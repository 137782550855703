import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import './Item.css';
import { AiOutlineCheck } from "react-icons/ai";
import { TbInfoCircle } from "react-icons/tb";
import 'moment/locale/ar';

function Item(props) {
  return (
    <div class=" col-6  col-lg-3 col-md-4 " >
    <div class="shadow  rounded d-flex flex-column justify-content-center px-2 align-items-center card-body " style={{ backgroundColor: "white",direction:'rtl' }}>

      {/*design to card */}
        <button className='detalsicon'  onClick={() => props.showItemDtls(props.itemObj)}>
        <TbInfoCircle className='fs-3' style={{ 'color':props.headerObj.buttonColor}}/>
        </button>
        
      <div style={{
        backgroundImage: `url(${props.itemObj.itemImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: "160px",
        width: '150px',
        direction:"ltr",
        backgroundPosition: 'center',
        marginTop: "-10%",

      }} onClick={() => props.changequt(props.itemObj)}>

        <button style={{  'width': '0%', 'height': '16%', 'border': '0', 'z-index': '100' }} className='btn checktrue'
          onChange={() => props.changequt(props.itemObj)} id='inputcheckqut'>

          {props.itemObj.itemQty > 0 && <AiOutlineCheck className='fs-1' style={{ 'color': 'red', 'width': '50px', 'height': '59px', 'margin-top': '-10px' }}></AiOutlineCheck>}  </button>
        {/* {props.itemObj.itemDiscountAmount == 0 ?
          <div style={{ "margin-top": '-10%', 'margin-left': '-9%' }}>

          </div>


          :
          <div className='flag ' style={{ 'position': 'absolute', 'background-color': props.headerObj.saleTagColor }}>sale <br></br> {props.itemObj.itemDiscountAmount}<span>جم</span>
          </div>

        } */}
    
      </div>

      <div className='card-body details' onClick={() => props.changequt(props.itemObj)}>
        <h5 className='card-title pt-2 '  id='cardname' style={{ 'color': props.headerObj.fontcolor,height:"100px",fontSize:"13px" }} >{props.itemObj.itemName}</h5>
        {props.itemObj.itemDiscountAmount == 0 ? <div className=" divbody mt-0">
          <h4 className='price' id='price' style={{ color: "" }}>  {props.itemObj.itemDiscountedPrice}</h4><span className='price '>جم</span> 
        </div>
          :
          <div className=" divbody mt-0">
            <h4 className='price ' id='price' dir='rtl'>{props.itemObj.itemDiscountedPrice} </h4><span className='price '>جم</span> 
            <h4 className='DiscountAmount' style={{ color: props.headerObj.saleTagColor }}> 
             {(props.itemObj.itemDiscountedPrice + props.itemObj.itemDiscountAmount).toFixed(2)}جم </h4>
          </div>}

      </div>
      <div className=' w-100 btn d-flex justify-content-between my-1 ' style={{ 'background': props.headerObj.buttonColor }}>
        <div className=' fs-2    text-white' onClick={() => props.btnonclick(props.itemObj, '+')} >
          <span className='margespan '>&#43;</span>
        </div>
        <div className='fs-4 text-white ' style={{direction:"ltr"}} id='quantity' onClick={() => props.showpopuppriceitem(props.itemObj)} >
        {typeof props.itemObj.itemQty === 'number' ? props.itemObj.itemQty.toFixed(2) : props.itemObj.itemQty}
        </div>
        <div className=' text-white fs-2' onClick={() => props.ChangeQty(props.itemObj, '-')}>
          &#8722;</div>
      </div>
    </div>
  
  </div>
  );

};

export default Item;
