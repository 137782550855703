import 'bootstrap/dist/css/bootstrap.css';
import React, { useState } from 'react';
import './container.css';
import { BsCart2 } from "react-icons/bs";
import { useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import EditIcon from '@mui/icons-material/Edit';
import $ from 'jquery';
import Swal from "sweetalert2";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { IoBackspaceSharp } from "react-icons/io5";
import { RxDotFilled } from "react-icons/rx";
import { useRef } from 'react';
import moment from 'moment';
import 'moment/locale/ar';
import { CiFilter } from "react-icons/ci";
import Slider from "@mui/material/Slider";
import axios from "axios";
import Item from '../Components/Item.js'
import InfiniteScrolling from '../Components/InfiniteScrolling.js'


function BasicExample(props) {
  const [page, setPage] = useState(1);
  const [range, setRange] = React.useState([0, 100000]);
  function handleChanges(event, newValue) {
    setRange(newValue);
 }
 const FnumDecimal=parseFloat("0.125").toFixed(2)
 const SnumDecimal=parseFloat("0.25").toFixed(2)
 const THnumDecimal=parseFloat("0.33").toFixed(2)
 const FOnumDecimal=parseFloat("0.5").toFixed(2)
  const safeDocument = typeof document !== 'undefined' ? document : {};

  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;
  const [popupprice, setpopupprice] = useState(false)
  const [showarray, setshowarray] = useState(false)
  const [array, setarray] = useState([])
  const [btnval, setbtnvalue] = useState()
  const [priceinpopupprice, setpriceinpopupprice] = useState()
  const [eqvitem, seteqvitem] = useState()
  const [dropdowneqv, setdropdowneqv] = useState(false)

  const[iconbgcolor,seticonbgcolor]=useState()


  const [iddrodwn, setiddrodwn] = useState()
  const [eqvid, seteqvid] = useState()
  const [eqvname, seteqvname] = useState()
  const [itemObj, setitemObj] = useState()
  const [LogoImageBase64, setLogoImageBase64] = useState("")
  // const [popupofaddone, setpopupofaddone] = useState(false)
  const [Section, setSection] = useState([]);

  useEffect(() => {
    setSection(props.headerObj.sections);
    getBase64ImageFromUrl(props.headerObj.logo)
    .then(result => setLogoImageBase64(result))
    .catch(err => console.error(err));
    setidofsec(props.headerObj.sections[0].sectionID);
    seticonbgcolor("#dddddd")
  }, []);

  function showItemDtls(ItemObj){
    props.setshowItemDtlsPopup(true)
    props.setItemDtlsObj(ItemObj)
  }
  


  
  /**
   * Determines the operating system based on the user agent.
   *
   * @return {string} The name of the operating system (iOS, Android, Windows, Mac, Linux, or Unknown).
   */
  const getOperatingSystem = () => {
    const userAgent = window.navigator.userAgent;
    const platforms = {
      ios: /iPad|iPhone|iPod/.test(userAgent),
      android: /Android/.test(userAgent),
      windows: /Win/.test(userAgent),
      mac: /Mac/.test(userAgent),
      linux: /Linux/.test(userAgent),
    };
  
    // Determine the operating system
    if (platforms.ios) {
      return 'iOS';
    } else if (platforms.android) {
      return 'Android';
    } else if (platforms.windows) {
      return 'Windows';
    } else if (platforms.mac) {
      return 'Mac';
    } else if (platforms.linux) {
      return 'Linux';
    } else {
      return 'Unknown';
    }
  };


  const ChangeQty2 = (Item) => {
    // Update state with incremented value
    if (array.length == 0) {
      if(Item.qty==0){
        // Swal.fire("من فضلك ادخل الكميه")
        setpopupprice(false)

      }
       else{
        orderdataInpop(Item)
        setpopupprice(false)
       }
    }
   else if (array.length == 1) {
      Item.qty = array[0].toString()
      if(Item.itemMaximumOrderQty==0){
        orderdataInpop(Item);
      }
      else{
        if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.qty=Item.itemMaximumOrderQty
          orderdataInpop(Item);
        }
        else{
          orderdataInpop(Item);
        }
      }
     }
    else if (array.length == 2) {
      Item.qty = array[0].toString() + array[1].toString();
      if(Item.itemMaximumOrderQty==0){
        orderdataInpop(Item);
      }
     else{
        if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.qty=Item.itemMaximumOrderQty
          orderdataInpop(Item);
        }
        else{
          if(parseInt(Item.itemQty)>Item.itemMaximumOrderQty){
            Swal.fire("لقد تعديت الحد الاقصي")
            Item.qty=Item.itemMaximumOrderQty
            orderdataInpop(Item);
          }
          else{
            orderdataInpop(Item);
          }
        }
  
      
    }
  }
    else if (array.length == 3) {
      Item.qty = array[0].toString() + array[1].toString() + array[2].toString();
      if(Item.itemMaximumOrderQty==0){
        orderdataInpop(Item);
      }
      else{
        if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.qty=Item.itemMaximumOrderQty
          orderdataInpop(Item);
        }
        else{
          if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
            Swal.fire("لقد تعديت الحد الاقصي")
            Item.qty=Item.itemMaximumOrderQty
            orderdataInpop(Item);
          }
          else{
            orderdataInpop(Item);
          }
        }
  
      
    }
  
 
    }
    else if (array.length == 4 ) {
      Item.qty = array[0].toString() + array[1].toString() + array[2].toString() + array[3].toString();
      if(Item.itemMaximumOrderQty==0){
        orderdataInpop(Item);
      }
      else{
        if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.qty=Item.itemMaximumOrderQty
          orderdataInpop(Item);
        }
        else{
          if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
            Swal.fire("لقد تعديت الحد الاقصي")
            Item.qty=Item.itemMaximumOrderQty
            orderdataInpop(Item);
          }
          else{
            orderdataInpop(Item);
          }
        }
  
      
    }
   

    
    }
    else if (array.length > 4) {
      if(Item.qty>Item.itemMaximumOrderQty  && Item.itemMaximumOrderQty > 0){
        Swal.fire("لقد تعديت الحد الاقصي")
      }
      else{
        orderdataInpop(Item);
  
      }
    }
    // if(parseInt(Item.qty)>Item.itemMaximumOrderQty){
    //   Swal.fire("لقد تعديت الحد الاقصي")
    //   Item.qty=Item.itemMaximumOrderQty
    //   orderdataInpop(Item)
    // }
    
    const updatedSection = [...Section]; // Create a copy of the Section array
    if(filteredItems.length > 0)
    {
    const updatedfilteredItems = [...filteredItems]; // Create a copy of the Section array
    updatedfilteredItems.forEach((item) => {
    if(Item.itemID === item.itemID) {
      item.itemQty = Item.qty;
    }
    });
    }

    updatedSection.forEach((section) => {
      section.items.forEach((item) => {
        if (item.itemID === Item.itemID) {
            item.itemQty = Item.qty;
           // Update the item's itemQty with Item.qty
        }
      });
    });
    
    setSection(updatedSection);
    
    
  }



  function showarrayleng(item) {
    
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false; 

  $("#itemop").css("pointer-events", "visible");

  $("#itemop").css("opacity", "1");
  $("#Headop").css("opacity", "1");
  $("#sliderOp").css("opacity", "1");
  $("#recordOp").css("opacity", "1");
  $("#formOp").css("opacity", "1");
  $("#footer").css("opacity", "1");
    ChangeQty2(item)
    setpopupprice(false)
    setarray([])
    setshowarray(false)
  }
  function setbtnval1(value) {
  
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)
      setarray(current => [...current, value]);
      setshowarray(true)
    }

  }
  // $( "#itemop" ).scrollTop();


  function closepricepopup() {


    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;

    // $("#Allcontainer").css("background", "white");
    // const $body = document.querySelector('body');
    // $body.style.removeProperty('overflow');
    // $body.style.position = 'relative';
    // // $body.style.removeProperty('position');
    // $body.style.removeProperty('width');
    // // window.scrollTo({top: scroll});
    setpopupprice(false)
    setarray([])
    setshowarray(false)
    
  $("#itemop").css("pointer-events", "visible");
  $("#itemop").css("opacity", "1");
  $("#Headop").css("opacity", "1");
  $("#sliderOp").css("opacity", "1");
  $("#recordOp").css("opacity", "1");
  $("#formOp").css("opacity", "1");
  $("#footer").css("opacity", "1");
  // $body.style.top = `${scroll}px`;

  }

  const zeroitem = (Item) => {
    // Update state with incremented value

    setpopupprice(false)
    Item.itemQty = 0;
    orderdata(Item);
  }

  const showpopuppriceitem=(order)=>{
   const objorder = {
        'itemID': order.itemID,
        'qty': order.itemQty,
        'itemName': order.itemName,
        'pricedel': order.itemSalesPrice,
        'price': order.itemDiscountedPrice,
        'discount': order.itemSalesPrice - order.itemDiscountedPrice,
        "total": order.itemDiscountedPrice,
        "netprice": order.itemDiscountedPrice,
        'deliveryOrder':props.headerObj.defaultDeliveryPrice,
        "itemMaximumOrderQty":order.itemMaximumOrderQty,
        "itemEqvUnts":order.itemEqvUnts,
        "defaultUnt":order.defaultUnt
  
      }
      showpopupprice(objorder);
 
  
      }

  
 
  const showpopupprice = (order) => {
    setpopupprice(true)
    setpriceinpopupprice(order.netprice)
    // setiteminbtn(order)
    // setdropdowneqv(false)
    setitemObj(order)
    // var scrollpos = window.scrollY;
    // setscroll(scrollpos)
    
    // $("#Allcontainer").css("overflow", "hidden");
    // $("#root").css("overflow", "hidden");
    // $("#root").css("position", "relative");
    // $("#popup").css("position", "absolute");

    // $body.style.top = `-${scrollpos}px`;
    // $body.style.width = '100%';
    if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = 'relative'; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    // body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
    // $(":button").attr('disabled', true);//button remains disabled
    $("#itemop").css("pointer-events", "none");
    $("#itemop").css("opacity", "0.5");
    $("#Headop").css("opacity", "0.5");
    $("#sliderOp").css("opacity", "0.5");
    $("#recordOp").css("opacity", "0.5");
    $("#formOp").css("opacity", "0.5");
    $("#footer").css("opacity", "0.5");
    // $("#Allcontainer").css("background", "#919294");
    // window.scrollTo({top: scrollpos});
    if(order.defaultUnt.isDecimal==true){
      $("#popup").css("height", "535px");
    }
    else{
      $("#popup").css("height", "515px");
    }
    // document.body.style.top = `-${window.scrollY}px`
    // document.body.style.position = 'fixed'
  //   $('body').css({
  //     overflow: 'hidden',
  //     height: '100%'
  // });
    //   setNameinpopupprice(itemObj.itemName)

  }


  // const [total, settotal] = React.useState(objtotal);
  //////////////////////function to increase button and update the value of quantity /////////////////
  const ChangeQty = (Item, Sign) => {
    // Update state with incremented value
    if (Sign == '+')
      var quantity = parseFloat(Item.itemQty) + 1;
    else if (Sign == '-')
      var quantity = parseFloat(Item.itemQty) - 1;
    else if (Sign == '0')
      var quantity = 0;

    if (quantity > Item.itemMaximumOrderQty && Item.itemMaximumOrderQty > 0) {
      // +Item.itemMaximumOrderQty
      Swal.fire("  لقد وصلت الي اقصي كميه لهذا الصنف" )
      quantity = Item.itemMaximumOrderQty;
    }
    else if (quantity < 0) {
      Swal.fire("تم حذف الصنف من قائمتك يمكن اعاده اختياره ")
      quantity = 0;
    }

    Item.itemQty = quantity.toFixed(2);
    orderdata(Item);
    // setitemObj(Item)
    // setiteminbtn(Item)
  }

  ////////////////order list /////////////////
  /////////use order for orderdata////

const orderdata = (order) => {
    ///define object of order and set the value 
    var delvalue = 0
    const orderlist = props.OrderItemLst
    if(props.regionvalue!=undefined)
    delvalue = props.regionvalue.deliveryFees;
    const objorder = {
      'itemID': order.itemID,
      'qty': order.itemQty,
      'itemName': order.itemName,
      'pricedel': order.itemSalesPrice * order.itemQty,
      'price': order.itemDiscountedPrice * order.itemQty,
      'discount': order.itemSalesPrice - order.itemDiscountedPrice,
      "total": order.itemDiscountedPrice,
      "netprice": order.itemDiscountedPrice,
      'deliveryOrder': delvalue,
      "itemMaximumOrderQty":order.itemMaximumOrderQty,
      "itemEqvUnts":order.itemEqvUnts,
      "defaultUnt":order.defaultUnt
    }

    let SecItem = Section.find(sectio => sectio.sectionID === order.sectionID);
if(SecItem != null){
var item = SecItem.items.find(it => it.itemID === order.itemID);
item.itemQty = order.itemQty;
}

    let totalpricedel = 0;
    let totalqty = 0;
    let totalprice = 0;
    let totaldiscount = 0;
    let Nettotal = 0;
    //condition of quantaity
    if (order.itemQty != 0) {
      //set the order in temporary array and slice orderlist 
      let index = orderlist.findIndex(orderlist => orderlist.itemID === order.itemID);
      if (index !== -1) {
        let temporaryarray = orderlist.slice();
        //asign temporary arry of index to orderdata 
        temporaryarray[index]["qty"] = order.itemQty;
        temporaryarray[index]["pricedel"] = order.itemSalesPrice ;
        temporaryarray[index]["total"] = order.itemQty * order.itemDiscountedPrice;
        temporaryarray[index]["price"] = order.itemDiscountedPrice ;
        temporaryarray[index]["netprice"] = order.itemDiscountedPrice;
        temporaryarray[index]["itemMaximumOrderQty"] = order.itemMaximumOrderQty;
        temporaryarray[index]["itemEqvUnts"] =order.itemEqvUnts;


        //loop for temporary array to show the data 
        temporaryarray.forEach((ele) => {
          totalqty = totalqty + Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + (ele.discount * ele.qty);
          totalprice = totalprice + ele.price;
          Nettotal = Nettotal + Number(ele.total);
        })
        // if quantaity !=-1 
      } else {
        const NeworderLst = [...orderlist, objorder];
        props.SetOrderItemLst(NeworderLst)
        //set te data of neworderlist and loop to that 
        NeworderLst.forEach((ele) => {
          totalqty = totalqty + Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + (Number(ele.discount.toFixed(2)) * ele.qty);
          totalprice = totalprice + ele.price;
          Nettotal = Nettotal + Number(ele.total);
        })

      }
      //create object ototal and  set total to object total  
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      // settotal(objtotal);
      props.settotal(objtotal);
      localStorage.setItem('objtotal', JSON.stringify(objtotal))

    }
    // when quantity = 0
    else {
      let copy = [...orderlist]
      copy = copy.filter(
        (Item, id) => order.itemID != Item.itemID
      )
      props.SetOrderItemLst(copy)

      copy.forEach((ele) => {
     totalqty = totalqty + Number(ele.qty);
        totalpricedel = totalpricedel + ele.pricedel;
        totaldiscount = totaldiscount + ele.discount;
        totalprice = totalprice + ele.price;
        Nettotal = Nettotal + ele.total + delvalue;
      })
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount * totalqty,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      // settotal(objtotal);
      props.settotal(objtotal);
      localStorage.setItem('objtotal', JSON.stringify(objtotal))

    }
   
    // onject set value of oreder 
  
    //define total to use it in final of table 
   
  }

  const orderdataInpop = (order) => {
    ///define object of order and set the value 
    const orderlist = props.OrderItemLst
    // onject set value of oreder 
  if(props.regionvalue==undefined){
    const objorder = {
      'itemID': order.itemID,
      'qty': order.qty,
      'itemName': order.itemName,
      'pricedel': order.pricedel ,
      'price': order.price,
      'discount': order.pricedel - order.price,
      "total": order.price * order.qty,
      "netprice": order.pricedel,
      'deliveryOrder': props.headerObj.defaultDeliveryPrice,
      "defaultUnt":order.defaultUnt,
      "itemEqvUnts":order.itemEqvUnts,
      
    }
    //define total to use it in final of table 
    let totalpricedel = 0;
    let totalqty = 0;
    let totalprice = 0;
    let totaldiscount = 0;
    let Nettotal = 0;
    //condition of quantaity
    if (order.itemQty != 0) {
      //set the order in temporary array and slice orderlist 
      let index = orderlist.findIndex(orderlist => orderlist.itemID === order.itemID);
      if (index !== -1) {
        let temporaryarray = orderlist.slice();
        //asign temporary arry of index to orderdata 
        temporaryarray[index]["qty"] = order.qty;
        temporaryarray[index]["pricedel"] = order.pricedel ;
        temporaryarray[index]["total"] = order.qty * order.price;
        temporaryarray[index]["price"] = order.price ;
        temporaryarray[index]["netprice"] = order.price;
                temporaryarray[index]["itemEqvUnts"] = order.itemEqvUnts;

        //loop for temporary array to show the data 
        temporaryarray.forEach((ele) => {
          totalqty = totalqty + Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + (ele.discount * ele.qty);
          totalprice = totalprice + ele.price;
          Nettotal = Nettotal + ele.total;
        })
        // if quantaity !=-1 
      } else {
        const NeworderLst = [...orderlist, objorder];
        props.SetOrderItemLst(NeworderLst)
        //set te data of neworderlist and loop to that 
        NeworderLst.forEach((ele) => {
          totalqty = Number (totalqty )+ Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + (Number(ele.discount.toFixed(2)) * ele.qty);
          totalprice = totalprice + ele.price;
          Nettotal = Number(Nettotal )+ Number( ele.total);
        })

      }
      //create object ototal and  set total to object total  
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      // settotal(objtotal);
      props.settotal(objtotal);
    }
    // when quantity = 0
    else {
      let copy = [...orderlist]
      copy = copy.filter(
        (Item, id) => order.itemID != Item.itemID
      )
      props.SetOrderItemLst(copy)

      copy.forEach((ele) => {
        totalqty = totalqty + ele.qty;
        totalpricedel = totalpricedel + ele.pricedel;
        totaldiscount = totaldiscount + ele.discount;
        totalprice = totalprice + ele.price;
        if(props.regionvalue.deliveryFees==undefined){
          Nettotal = Nettotal + ele.total ;

        }
        else{
          Nettotal = Nettotal + ele.total + props.regionvalue.deliveryFees;

        }
      })
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount * totalqty,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      // settotal(objtotal);
      props.settotal(objtotal);
    }
  }
  else{
    const objorder = {
      'itemID': order.itemID,
      'qty': order.qty,
      'itemName': order.itemName,
      'pricedel': order.pricedel * order.qty,
      'price': order.price * order.qty,
      'discount': order.pricedel - order.price,
      "total": order.price,
      "netprice": order.price,
      'deliveryOrder': props.regionvalue.deliveryFees
    }
    //define total to use it in final of table 
    let totalpricedel = 0;
    let totalqty = 0;
    let totalprice = 0;
    let totaldiscount = 0;
    let Nettotal = 0;
    //condition of quantaity
    if (order.itemQty != 0) {
      //set the order in temporary array and slice orderlist 
      let index = orderlist.findIndex(orderlist => orderlist.itemID === order.itemID);
      if (index !== -1) {
        let temporaryarray = orderlist.slice();
        //asign temporary arry of index to orderdata 
        temporaryarray[index]["qty"] = order.qty;
        temporaryarray[index]["pricedel"] = order.pricedel ;
        temporaryarray[index]["total"] = order.qty * order.price;
        temporaryarray[index]["price"] = order.price ;
        temporaryarray[index]["netprice"] = order.price;
                        temporaryarray[index]["itemEqvUnts"] = order.itemEqvUnts;

        //loop for temporary array to show the data 
        temporaryarray.forEach((ele) => {
          totalqty = totalqty + Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + (ele.discount * ele.qty);
          totalprice = totalprice + ele.price;
          Nettotal = Nettotal + ele.total;
        })
        // if quantaity !=-1 
      } else {
        const NeworderLst = [...orderlist, objorder];
        props.SetOrderItemLst(NeworderLst)
        //set te data of neworderlist and loop to that 
        NeworderLst.forEach((ele) => {
          totalqty = Number (totalqty )+ Number(ele.qty);
          totalpricedel = totalpricedel + ele.pricedel;
          totaldiscount = totaldiscount + (Number(ele.discount.toFixed(2)) * ele.qty);
          totalprice = totalprice + ele.price;
          Nettotal = Number(Nettotal )+ Number( ele.total);
        })

      }
      //create object ototal and  set total to object total  
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      // settotal(objtotal);
      props.settotal(objtotal);
    }
    // when quantity = 0
    else {
      let copy = [...orderlist]
      copy = copy.filter(
        (Item, id) => order.itemID != Item.itemID
      )
      props.SetOrderItemLst(copy)

      copy.forEach((ele) => {
        totalqty = totalqty + ele.qty;
        totalpricedel = totalpricedel + ele.pricedel;
        totaldiscount = totaldiscount + ele.discount;
        totalprice = totalprice + ele.price;
        if(props.regionvalue.deliveryFees==undefined){
          Nettotal = Nettotal + ele.total ;

        }
        else{
          Nettotal = Nettotal + ele.total + props.regionvalue.deliveryFees;

        }
      })
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount * totalqty,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      // settotal(objtotal);
      props.settotal(objtotal);
    }
  }

  }

  // function to delete row if quantity = 0
  // const deleterow = (Itemid)=>{
  //   let copy = [...orderlist]
  //   copy =copy.filter(
  //     (order,Itemid) => Itemid != order.id
  //   )
  //   props.SetOrderItemLst(copy)
  // }
  /////////////function button change quntaty////////////////
  const btnonclick = (Item, Sign, Objid) => {
    ChangeQty(Item, Sign)
  }
  const [comment, setMessage] = useState('');

  const handleMessageChange = event => {
    // 👇️ access textarea value
    const x = event.target.value
    setMessage(x);
  };
  //  document.addEventListener('DOMContentLoaded', function() {
  //   // Find the tallest height among all h6 tags
  //   let maxHeight = 0;
  //   const detailsElements = document.querySelectorAll('.details');

  //   detailsElements.forEach(function(element) {
  //     maxHeight = Math.max(maxHeight, element.offsetHeight);
  //   });

  //   // Set the same height for all h6 tags
  //   detailsElements.forEach(function(element) {
  //     element.style.height = maxHeight + 'px';
  //   });
  // });
  ////////section id ////
  // control the card length
  $(document).ready(function () {
    // Find the tallest height among all h6 tags
    let maxHeight = 0;
    $('.card-title').each(function () {
      maxHeight = Math.max(maxHeight, $(this).outerHeight());
    });
    
    // Set the same height for all h6 tags
    $('.card-title').css('height', maxHeight + 'px');
  });
  // end control the card length
 
  // // Update state with incremented value
  const changequt = (Item) => {
    if (Item.itemQty == 0)
      ChangeQty(Item, '+');
    else
      ChangeQty(Item, '0');
  }

   // Update state with incremented value
   function findSectionID(sections, itemID) {
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const items = section.items;
  
      for (let j = 0; j < items.length; j++) {
        const item = items[j];
        if (item.itemID === itemID) {
          return section.sectionID;
        }
      }
    }
  
    return null; // Item ID not found
  }
  const zeroitem2 = (Item, section) => {
  //   $("#Allcontainer").css("background", "white");

  //   $('body').css({
  //     overflow: 'scroll',
  //     height: '100%'
  // });

    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;

  $("#itemop").css("opacity", "1");
  $("#Headop").css("opacity", "1");
  $("#sliderOp").css("opacity", "1");
  $("#recordOp").css("opacity", "1");
  $("#formOp").css("opacity", "1");
  $("#footer").css("opacity", "1");
  $("#itemop").css("pointer-events", "visible");

const sectionID = findSectionID(section, Item.itemID);

    let index = Section.findIndex(Section => Section.sectionID === sectionID);
    let itemsec = Section[index].items
    let index2 = itemsec.findIndex(itemsec => itemsec.itemID === Item.itemID);

    setpopupprice(false)
    itemsec[index2].itemQty = 0
    orderdata(itemsec[index2]);
    setarray([])

  }
  function setbtnval1(value) {
 
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }


 
  }
   }
  }
  function setbtnval2(value) {
   
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);

 
  }
   }
  }
  function setbtnval3(value) {

    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);

 
  }
   }
  }
  function setbtnval4(value) {
 
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);

 
  }
   }
  }
  function setbtnval5(value) {

    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);

 
  }
   }
      // const myArrayS = array.split(".");
      // value
    
  }
  function setbtnval6(value) {


    if(array.length>3){
      Swal.fire("للقطع لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);

 
  }
   }

  }
  function setbtnval7(value) {

 
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);

 
  }
   }

  }
  function setbtnval8(value) {

   
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

      setarray(current => [...current, value]);
      setshowarray(true)
    }
  }
  function setbtnval9(value) {

   
    if(array.length>3){
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else{
      setbtnvalue(btnval + value)

   
   if(array.length ==0){
    setarray(current => [...current, value]);
    setshowarray(true)
   
   }
   else{
    const indexofDel= array.indexOf(".00")
    if(indexofDel==-1){
      setarray(current => [...current,value]);
      setshowarray(true)
    }
    else{
      array[indexofDel]= "." +value

      setarray(current => [...current]);
      setshowarray(true)
    }
    // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);

 
  }
   }
  }
  function setbtnval10(value) {
    
    if (array.length == 0) {
      setarray([])
      Swal.fire({
        title: 'يجب ادخال كميه  ',
        icon: 'warning',

        confirmButtonText: 'تم'
      })
    }
    else {
    
      if(array.length>3){
        Swal.fire("لقد تعديت الحد الاقصي")
      }
      else{
        setbtnvalue(btnval + value)
  
     
     if(array.length ==0){
      setarray(current => [...current, value]);
      setshowarray(true)
     
     }
     else{
      const indexofDel= array.indexOf(".00")
      if(indexofDel==-1){
        setarray(current => [...current,value]);
        setshowarray(true)
      }
      else{
        array[indexofDel]= "." +value
  
        setarray(current => [...current]);
        setshowarray(true)
      }
      // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
  
   
    }
     }
    }


  }
  function backspacebtn(){
array.pop()
setarray(current => [...current]);




  }
  const createScreenshotPDF = () => {
    const MARGIN = 2;

    // get the content element
    document.getElementById('orderprint2').style.display="block"
    const content = document.getElementById('orderprint2');
  
    // get content size
    const width = 397;
    const height = content.clientHeight;
  
    // call html2canvas to save the screenshot to a pdf file
    html2canvas(content, {
      scrollX: 0, // scroll to X origin
      scrollY: 5, // scroll to Y origin
      scale: 5, // 5 is quality scale
    }).then(function (canvas) {
      // convert the canvas conent to a Data URI/URL
      var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
      var doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px', // set pixel as unit
        format: [width + MARGIN * 1, height + MARGIN * 1],
      });
  
      // add canvas as an image
      doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);
  
      // save the content to screenshot.pdf
      doc.save('الاوردر.pdf');
    });
    document.getElementById('orderprint2').style.display="none"

  };

async function getBase64ImageFromUrl(imageUrl) {
  var res = await fetch(imageUrl);
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}
  const createScreenshotPDFMob = () => {
  const MARGIN = 2;
  // get the content element
  const content = document.getElementById('printablediv');
   content.style.display="block";



  // // get content size
  const width = content.offsetWidth ;
  const height = content.clientHeight;
  // var opt = {
  //   margin:       0.2,
  //   filename:     'اوردر.pdf',
  //   image:        { type: 'jpeg', quality: 0.98 },
  //   jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
  // };

  // html2pdf(content, opt).then(() => {
  //   content.style.display="none";

  // });

  // call html2canvas to save the screenshot to a pdf file
  html2canvas(content, {
    scrollX: 0, // scroll to X origin
    scrollY: 5, // scroll to Y origin
    scale: 5, // 5 is quality scale
  }).then(function (canvas) {
    // convert the canvas conent to a Data URI/URL
    var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
    var doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px', // set pixel as unit
      format: [width + MARGIN * 1, height + MARGIN * 1],
    });

    // add canvas as an image
    doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);

    // save the content to screenshot.pdf
    if(getOperatingSystem() != "ios"){
     doc.save('اوردر.pdf');
    }
    else{
       // Display the PDF in the browser
       const pdfData = doc.output('blob');
       const blobUrl = URL.createObjectURL(pdfData);
       window.open(blobUrl);
    }
  });
  content.style.display="none";
};
  // const createScreenshotPDFMob = () => {
  //   const MARGIN = 2;
  //   // get the content element
  //   document.getElementById('printablediv').style.display="block"
  //   const content = document.getElementById('printablediv');
  
  
  
  //   // get content size
  //   const width = content.offsetWidth ;
  //   const height = content.clientHeight;
  
  //   // call html2canvas to save the screenshot to a pdf file
  //   html2canvas(content, {
  //     scrollX: 0, // scroll to X origin
  //     scrollY: 5, // scroll to Y origin
  //     scale: 5, // 5 is quality scale
  //   }).then(function (canvas) {
  //     // convert the canvas conent to a Data URI/URL
  //     var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
  //     var doc = new jsPDF({
  //       orientation: 'portrait',
  //       unit: 'px', // set pixel as unit
  //       format: [width + MARGIN * 1, height + MARGIN * 1],
  //     });
  
  //     // add canvas as an image
  //     doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);
  
  //     // save the content to screenshot.pdf
  //     doc.save('الطلبيه.pdf');
  //   });
  // };
  function fDecimalNumber(value){
    const str =value.toString()
    // const str = value.replace(/^0+/, '');
    const myArray = str.split("0");
    // value

    if(array.length==1){
    let index = array.findIndex(array =>array === myArray[1]);
  
    if(index===-1){
      
      value=(parseFloat(myArray[1]))
      myArray[1]=value+parseFloat(array[0])
      array[0]=0 + myArray[1]
    
    
    
          setarray(current => [...array]);
          localStorage.setItem("btnval",myArray[1])
    
    }
    else{
      array.splice(index);
    
      value=parseFloat(myArray[1])+parseFloat(value)
      const str1 =value.toString()
    
      const array2=str1.split("0")
      // array.splice(index,value);
      setarray(current => [...array,value]);
      localStorage.setItem("btnval",myArray[1])
  
    
    }
  }
  else{
    let index = array.findIndex(array =>array === localStorage.getItem("btnval"));
  
    if(index===-1){
      if(array.toString().includes(".")==true){
        array.indexOf(".");
        const myArray = str.split("0");
        value=parseFloat(myArray[1])+parseFloat(value)
     
        let sum = "";

        array.forEach(item => {
            sum += item;
        });
        
    
    let thevalue=(parseFloat(myArray[1])+parseFloat(sum)).toString();
        setarray(current => [thevalue]);
      }
      else{
        value=parseFloat(myArray[1])
        if(array.length==0){
          let zero=0;
          myArray[1]=zero.toString() + myArray[1]
      
      
          setarray(current => [...array,myArray[1]]);
        }
        else{
          let zero="";
          myArray[1]=zero.toString() + myArray[1]
      
      
          setarray(current => [...array,myArray[1]]);
        }
   
      }
  
          
    
    }
    else{
      array.splice(index);
    
      value=parseFloat(localStorage.getItem("btnval"))+parseFloat(value)
      const str1 =value.toString()
    
      const array2=str1.split("0")
      // array.splice(index,value);
      setarray(current => [...array,array2[1]]);
    
    }
  }
  
  
  
  
  //     if(myArray[i].includes(".")){
  //       let index2 = myArray[i].findIndex(myArray[i]);
  
      
  //     const x=  parseFloat(myArray[i])
  //     setarray(current => [...current, myArray[i]]);
  //     }
  
    
    // myArray.includes(".");
  
    //   setshowarray(true)
  
  
  }
function showdropdown(id){
  if(dropdowneqv==false){
    setdropdowneqv(true)
    setiddrodwn(id)
    
  }
  else{
    setdropdowneqv(false)
  }
  }
  const seteqv= (item,EqvId,EqvName) => {
    // let index1 = item.findIndex(item => item.Id === id);
    seteqvitem(EqvName)
    seteqvid(EqvId)
    seteqvname(EqvName)
    item.eqvID=EqvId
  item.eqvName=EqvName
    orderdata(item);
  //   let index2 = itemeqv.findIndex(itemeqv => itemeqv.eqvID === EqvId);
  //   item.itemEqvUnts[index2].
  }
function fDecimalNumberpoint(value){
  const str =value.toString()
  // const str = value.replace(/^0+/, '');
  const myArray = str.split("*");
  // value

if(array.length==1 )
{ if( (array[0].toString()).includes(".")){
  let index = array.findIndex(array =>array === myArray[1]);

  if(index===-1){
    
    value=parseFloat(myArray[1])
   
  
  
  
        setarray(current => [...array]);
        localStorage.setItem("btnval",myArray[1])
  
  }
  else{
 
  if(array[0]==".00"){
   
    setarray(current => [...array]);
    localStorage.setItem("btnval",myArray[1])

  }
  else{
    array.splice(index);
    value=parseFloat(myArray[1])+parseFloat(value)
    const str1 =value.toString()
  
    const array2=str1.split("0")
    // array.splice(index,value);
    setarray(current => [...array,array2[1]]);
    localStorage.setItem("btnval",myArray[1])
  }


  
  }
}
else{
  let index = array.findIndex(array =>array === myArray[1]);

  if(index===-1){
    parseFloat(array)
    value=parseFloat(myArray[1])
   
  
  
  
        setarray(current => [...array,myArray[1]]);
        localStorage.setItem("btnval",myArray[1])
  
  }
  else{
    array.splice(index);
  
    value=parseFloat(myArray[1])+parseFloat(value)
    const str1 =value.toString()
  
    const array2=str1.split("0")
    // array.splice(index,value);
    setarray(current => [...array,array2[1]]);
    localStorage.setItem("btnval",myArray[1])

  
  }
}

}
else{
  let index = array.findIndex(array =>array === localStorage.getItem("btnval"));

  if(index===-1){
    
    value=parseFloat(0+myArray[1])
   
  if(array.length==0){
    setarray(current => [...array,myArray[1]]);

  }
  else{
    setarray(current => [...array]);
  }
  
     
        
  
  }
  else{
    array.splice(index);
  
    const str1 =value.toString()
  
    const array2=str1.split("0")
    value=parseFloat(localStorage.getItem("btnval"))+parseFloat(value)

    // array.splice(index,value);
    setarray(current => [...array,array2[1]]);
  
  }
}
}
const[idofesec,setidofsec]  =useState()
const[idofesecpopup,setidofsecpopup]  =useState(null)


const showsec=(id)=>{
  seticonbgcolor("#dddddd")
  setidofsec(id);
  setPage(1);

  return axios.get('https://tijarymagazineapis.azurewebsites.net/api/MagazineHead/GetPagedView/' + props.magazineID+"/" + id+"/1")
  .then((response) => {

    const updatedSection = [...response.data]; 

    const updatedList = updatedSection.map((section) => ({
      ...section,
      items: section.items.map((item) => {
        const updateObject = props.OrderItemLst.find(updateItem => updateItem.itemID === item.itemID);
        if (updateObject) {
          return { ...item, itemQty: updateObject.qty };
        }
        return item;
      }),
    }));
   setSection(updatedList)
  })
}

const showsecpopup=(id)=>{
  if(id==null){
    range[0]=0
    range[1]=100000

  }
  setidofsecpopup(id)

}

const [inputValue, setInputValue] = useState('');

  // const [filteredSections, setFilteredSections] = useState([]);
  // const apiResponse = props.headerObj
  const [isFiltering, setIsFiltering] = useState(false); 
  const handleInputChange = (event) => {

    const value = event.target.value;
    setInputValue(value);
    
    if (value.trim() === '') {
      const searchfilter = document.getElementById('searchfilter'); 
      const cancelfilter = document.getElementById('cancelfilter'); 
    
      searchfilter.style.display="block"
      cancelfilter.style.display="none"
      // If the input value is empty or only contains whitespace, reset the filter
      setIsFiltering(false);
      const allItems = Section.reduce((accumulator, currentSection) => {
        return accumulator.concat(currentSection.items);
      }, []);
      setFilteredItems(allItems);
    }
   
    // ... rest of your logic for handling non-empty input
  };
  const handlePress = e => {
    if(e.key === 'Enter') { 
    
      fetchfilteritem(props.magazineID,range[0],range[1])
    }
   }
   const handlePresspopup = e => {
    if(e.key === 'Enter') { 
    
      fetchfilteritem(props.magazineID,range[0],range[1])
    }
   }
   

  const handleInputChangeinpopup = (event) => {
    const value = event.target.value;
    setInputValue(value);
    
    if (value.trim() === '') {
      const searchfilter = document.getElementById('searchfilter'); 
      const cancelfilter = document.getElementById('cancelfilter'); 
    
      searchfilter.style.display="block"
      cancelfilter.style.display="none"
      // If the input value is empty or only contains whitespace, reset the filter
      setIsFiltering(false);
      const allItems = Section.reduce((accumulator, currentSection) => {
        return accumulator.concat(currentSection.items);
      }, []);
      setFilteredItems(allItems);
    }
    else{
      const searchfilter = document.getElementById('searchfilter'); 
      const cancelfilter = document.getElementById('cancelfilter'); 
    
      searchfilter.style.display="none"
      cancelfilter.style.display="block"
    }
    // ... rest of your logic for handling non-empty input
  };

  // Filter the sections based on the input value


  // Update the filteredSections state
  const [filteredItems, setFilteredItems] = useState([]);
  // const [filteredItemspopup, setFilteredItemspopup] = useState([]);


  const restfilter =(e)=>{
  e.target.value=""
  const searchfilter = document.getElementById('searchfilter'); 
  const cancelfilter = document.getElementById('cancelfilter'); 

  searchfilter.style.display="block"
  cancelfilter.style.display="none"
  handleInputChange(e)

}
const restfilterpopup =(e)=>{
  e.target.value=""
  const searchfilterinpopup = document.getElementById('searchfilterinpopup'); 
  const cancelfilterpopup = document.getElementById('cancelfilterpopup'); 

  searchfilterinpopup.style.display="block"
  cancelfilterpopup.style.display="none"
  handleInputChangeinpopup(e)

}
// function closefilterpoup(){
//   document.body.style.overflowY = "scroll"

//   const bottomnavigation = document.getElementById('bottomnavigation');
//   bottomnavigation.style.display="flex"
//   setfilterpopup(false)
// }

const fetchfilteritem = (magazineID,PriceFrom,PriceTo) => {
   const popupfilterclass=document.getElementById("popupfilterclass")
  //  const popupfiltericon=document.getElementById("popupfiltericon")

  //  popupfiltericon.style.color="#31AB4D"

   if(popupfilterclass!=null){
    popupfilterclass.style.display="none"
   }

   
    const bottomnavigation = document.getElementById('bottomnavigation');
  bottomnavigation.style.display="flex"

var url = 'https://tijarymagazineapis.azurewebsites.net/MagazineItemFilter?MagazineHeadId='+ magazineID + '&' + 'PriceFrom=' + PriceFrom + '&' +'PriceTo='+PriceTo+'&'+'Name=' +inputValue
if(idofesecpopup != null){
  url = url +'&'+'SectionId='+idofesecpopup
}
  return axios.get(url)
        .then((response) => {
          filterItems(response.data,true)
        
        })

        
}

  const filterItems = (List,Search) => {
    const element = document.getElementById('filterclass'); 
  const searchfilter= document.getElementById('searchfilter');
  const cancelfilter= document.getElementById('cancelfilter');

  searchfilter.style.display="none"
  cancelfilter.style.display="block"

  document.body.style.overflowY = "scroll"

    setTimeout(() => {
      element.classList.remove("filterclassclick");
      element.classList.add("filterclass");
    }, 400);
  
    if (Search == null || Search == false) {
      setFilteredItems([]);
      setIsFiltering(false); // Set filter inactive
    } else {

      const updatedList = List.map(item => {
        // Find the corresponding update object based on the ID
        const updateObject = props.OrderItemLst.find(updateItem => updateItem.itemID === item.itemID);
      
        // Check if there is an update object for the current item
        if (updateObject) {
          // Apply your update logic here, for example, use the updateValue
          return { ...item, itemQty: updateObject.qty };
        }
        // If no update is needed, keep the original object
        return item;
      });

      setFilteredItems(updatedList);
      setIsFiltering(true); // Set filter active
    }
  };

// const[filterpopup,setfilterpopup] =useState(false)
 
// function showfilterpopup(){
//   document.body.style.overflow = "hidden"

//   const bottomnavigation = document.getElementById('bottomnavigation');
//   bottomnavigation.style.display="none"
//   if(filterpopup==true){
//     setfilterpopup(false)
//   }
//   else{
//   setfilterpopup(true)
//   }

// }

const[brand,setbrand] =useState(false)


  return (
    <div>
   <div className='container-fluid containermargin'>
<div className='row searchengine' >
<div className='filterclass' id='filterclass' onClick={props.showfilterpopup}>
<CiFilter  className='iconfiltermobile'/>
{/* <i class="fa-solid fa-filter " style={{}}></i> */}
</div>
  <div className=' paddingsearch'>
  <div class="inline">
        <div class="input-icons">
        
        <div className='iconclassnew'>
        <i class="fa-solid fa-magnifying-glass " id='searchfilter' onClick={() => {fetchfilteritem(props.magazineID,range[0],range[1])} }></i>
        <i class="fa-solid fa-xmark" id='cancelfilter' onClick={restfilter} style={{display:"none"}}> </i>

        </div>
      <div className='searchinput' onKeyDown={handlePress} > 
      <input class="input-field"  
      type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handlePress} 
        id='textoffilter'
        dir='ltr'
        placeholder="بحث عن"
          
        />
      </div>
          
           
        </div>
  </div>
  </div>
 
</div>
   </div>


   {isFiltering==true?
    <div className='container-fluid'>
    <div className='row g-2 itemmargin'>
   {filteredItems.map((itemObj, index) => (
             
              
        <Item showItemDtls = {showItemDtls} headerObj = {props.headerObj} itemObj = {itemObj} changequt = {changequt} btnonclick = {btnonclick} showpopuppriceitem = {showpopuppriceitem}/>
             
           ))}
      </div>
   </div>
   :<>
   <div className='container-fluid' style={{overflowY:"hidden",overflowX:"scroll", background: "white"}}>
  <div className='d-flex' style={{marginLeft: "0%",   }}>
  {props.headerObj.sectionsLst && props.headerObj.sectionsLst.length > 0 && props.headerObj.sectionsLst.map((SectionObj, index) => (
    <>
{SectionObj.sectionID==idofesec?
  <div className='col-lg-1 col-3 heighttab' style={{background:iconbgcolor,border: "2.5px solid #F37743",borderRadius: "6px"}} onClick={()=>showsec(SectionObj.sectionID)}>
{SectionObj.sectionIcon==null?

  <>
  <img className=' iconmargin' src={SectionObj.sectionBanner}></img>
  <p className='fontmaaray sectionnameclass'>{SectionObj.sectionName}</p>
  </>

:
<>
<img className=' iconmargin' style={{height:'70px',width:'70px',marginTop:'4%'}} src={SectionObj.sectionIcon}></img>
<p className='fontmaaray sectionnameclass'>{SectionObj.sectionName}</p>
</>

}

</div>


:
<div className='col-lg-1 col-3 heighttab'  onClick={()=>showsec(SectionObj.sectionID)}>
{SectionObj.sectionIcon==null?

  <>
  <img className=' iconmargin' src={SectionObj.sectionBanner}></img>
  <p className='fontmaaray sectionnameclass'>{SectionObj.sectionName}</p>

  </>

:
<>
<img className=' iconmargin' style={{height:'70px',width:'70px',marginTop:'4%'}}  src={SectionObj.sectionIcon}></img>
<p className='fontmaaray sectionnameclass'>{SectionObj.sectionName}</p>

</>


}

</div>}

    </>
  




  ))}
  </div>
  </div>
    

      <div className='container-fluid' id='itemop'  style={{direction:"rtl",overflow:"hidden"}}>

        <div className='row'>
          {Section && Section.length > 0 && 
           <InfiniteScrolling showItemDtls = {showItemDtls} headerObj = {props.headerObj}
            Section = {Section} setSection={setSection} changequt = {changequt} btnonclick = {btnonclick} 
            showpopuppriceitem = {showpopuppriceitem} magazineID = {props.magazineID}
            pageNo = {page} setPage = {setPage} 
            />}
        </div>
      </div>
   </>}

{props.filterpopup?
<div className='card popupfilterclass' id='popupfilterclass' >
<div className='row '  style={{ marginTop:"2%"}}>
<div className='closefilterpopup'>
<i class="fa-solid fa-xmark closepopupfilter"  style={{fontSize: "29px",color:"#F3824C"}} onClick={props.closefilterpoup}></i>
</div>
  <div className='paddingsearchpopup'>
  <div class="inline">
        <div class="input-iconss" onKeyDown={handlePresspopup}>
            <input class="input-fieldpopup fontmaaray "  type="text"
        value={inputValue}
        onChange={handleInputChangeinpopup}
      onKeyDown={handlePresspopup}
        id='textoffilter'
        dir='ltr'
        
        placeholder="بحث عن " />
        <i class="fa-solid fa-xmark" id='cancelfilterpopup' onClick={restfilterpopup} style={{display:"none"}}> </i>
          
           
        </div>
  </div>
  </div>
 
</div>
<div className='container-fluid'>
<div className='row' style={{direction:"rtl",padding:"1%"}}>
<div className='col-lg-2 col-md-4 col-6' >
<h5 className='fontmaaray ' style={{textAlign:"right"}}>السعر</h5>
</div>

<div className='sliderofrange'>
<div>
      <div>
      <Slider 
      className='colorslider'
        min={0}
        max={100000}
        step={1000}
        value={range}
        onChange={handleChanges}
        valueLabelDisplay="auto"
     direction="reverse-row"
      />
        <div style={{ padding:"1%"}}>
          <span className='fontmaaray ' style={{    position: "absolute",left:"5%"}}>0</span>
          {range[0]==0?"":         
           <span className='fontmaaray rangetext ' >{range[0]}-{range[1]}</span>
}

          <span className='fontmaaray ' style={{ position: "absolute",right:"4%" }}>{(100000).toLocaleString('en-US')}</span>
        </div>
      </div>

   
    </div>
       
</div>
</div>
<div className='row mt-4' style={{direction:"rtl", }}>
<div className='row'>
<div className='col-lg-2 col-md-4 col-6' >
<h5 className='fontmaaray ' style={{textAlign:"right"}}>فئة</h5>
</div>
</div>

<div className='row' style={{marginRight:"-0.5%",gap:"6%",paddingRight:"9px"}}>
{Section && Section.length > 0 && Section.map((SectionObj, index) => (
  <>
  {SectionObj.sectionID==idofesecpopup?
    <div  style={{background: "#F37743",borderRadius:"5px",marginTop:"1%",width:"47%",paddingLeft:"0px"}}
    onClick={()=>showsecpopup(SectionObj.sectionID)} >
  
  
  <p className='fontmaaray sectionnameclass text-white'>{SectionObj.sectionName}</p>

</div>
  
  
  
  
  :   
  <div  style={{background: "white",borderRadius:"5px",marginTop:"1%",width:"47%",paddingLeft:"0px"}}  onClick={()=>showsecpopup(SectionObj.sectionID)}>
  
  
  <p className='fontmaaray sectionnameclass'>{SectionObj.sectionName}</p>

</div>
}
 
  </>




))}
</div>

</div>
{brand==true?
  <div className='row' style={{direction:"rtl"}}>
<div className='col-lg-2 col-md-4 col-6' >
<h5 className='fontmaaray' style={{textAlign:"right"}}>براند</h5>
</div>
<div className='col-12'>
<div className='row'>

</div>
</div>
</div>

:""}


</div>
<div className='row' style={{marginTop:"5%",marginBottom:"5%",paddingRight: "1.5%",paddingLeft: "3%"}}> 
<div className="col-lg-2 col-md-4 col-6">
<button onClick={() => {showsecpopup(null)} } className="btn  w-100" style={{background:"#FF0000",color:"white"}}> مسح</button>
</div>
<div className="col-lg-2 col-md-4 col-6">
<button onClick={() => {fetchfilteritem(props.magazineID,range[0],range[1])} } className="btn  w-100" style={{background:"#265745",color:"white"}}>بحث</button>
</div>
</div>
</div>:""}
    



      <div className='container-fluid'>
        <div className='row justify-content-center '>
          <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
            <div className='container-fluid bord'>

              <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
                <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
                <h1 className='text-light col-10 text-center fontmaaray mt-1'>اوردر</h1>
              </div>
              <div className='row fontmaaray justify-content-center mt-3' id='myTable'>

                <h6 className='col-2 fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>خصم</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>سعر</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>إجمالي</h6>

                {props.OrderItemLst.map(order => (


                  <div className='row justify-content-center fontmaaray position-relative  my-2' >
                   <button id="printButton" className='btn btn-danger d-block d-md-none py-0 position-absolute' onClick={() => showpopupprice(order)} style={{ zIndex: "5", left: "5px", top: "-5px", width: "auto" }}><EditIcon sx={{ fontSize: "1rem" }}></EditIcon></button>
                    <h6 className='col-1 fontmaaray ' style={{ 'color': props.headerObj.fontcolor,direction:"ltr" }}>{order.qty}</h6>
                    <h6 className='col-11 fontmaaray pe-4  ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>
                    <div className='col-5'></div>
                    <h6 className='col-2 fontmaaray  ' style={{ 'color': props.headerObj.fontcolor }}>{(Number(order.discount).toFixed(2).toLocaleString())}  </h6>
                    <h6 className='col-3 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{Number(order.netprice).toFixed(2).toLocaleString()}</h6>
                    <h6 className='col-2 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{(order.total.toLocaleString())}</h6>
                    <hr className='mt-3 col-9' style={{ 'margin-right': '6%' }}></hr>

                  </div>


                ))}
                {popupprice ?

                  <div className='col-lg-3 col-md-6 col-11 card popup  p-0' id='popup' >
                    <div className='container-fluid p-0'>
                      <div className=' card-header w-100 p-0' style={{ height: "50px" }}>
                              {itemObj.discount == 0 ?
                                <div className='d-flex ' id={itemObj.itemID}>
                                              <div className='col-4 py-1'>
                                    <p className='fontmaaray x3' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{priceinpopupprice} جم</p>
                                  </div>
                                  <div className='col-8 p-0'>
                                 <div className='d-flex'>
<div className='col-8'>
<p className='fontmaaray x1' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl",textAlign:"left" }}>{itemObj.qty} &nbsp; {eqvitem} </p>

</div>
<div className='col-4'>

{itemObj.itemEqvUnts.map((itemObjeqv) => (
<p  id='eledropdown'  style={{borderBottom:"1px solid lightgrey",marginTop:"14%",cursor:"pointer"}}
>{itemObjeqv.eqvName}</p> ))}


</div> 

                                 </div>
                                  </div>
                      
                                </div> :
                                <div className=''>
                                <div className='d-flex justify-content-between'>
                                <div className=' py-1'>
                                  
                                    <p className='fontmaaray x6' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{itemObj.netprice} جم</p>

                                  </div>
                                  <div className=' py-1'>
                                  <p className='fontmaaray x4' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{itemObj.qty}</p>

                                  </div>
                                </div>
 


                                </div>}

                            
                          
                        </div>
                      </div>
                      <div className=' container-fluid p-2'>

                        <div className='row '>
                          <div className='col-12' style={{ fontSize: "20px" }}>
                            <p className='fontmaaray' style={{ textAlign: "center", marginTop: "8px", color: "green", fontWeight: "bold" }}>  {itemObj.itemName} </p>
                          </div>
                        </div>
                        <div className='row' style={{marginTop:"-3%",height:"40px"}}>
                          {array.length > 0?
                            <p className='fontmaaray x2 text-center' style={{ direction:"ltr",fontSize: " 22px", marginTop: "7px", fontWeight: "bold",textAlign:"left" }}> {(array)} &nbsp; {eqvitem} </p>

                          :""}
                          </div>
                          {itemObj.defaultUnt.isDecimal==true  ?
                          
                            <div className='d-flex' style={{height:"50px"}}>
                          <div className='col-2'>
<button className='btn btn-primary h-100 w-100' onClick={() => fDecimalNumber(FnumDecimal)} >1/8</button>

</div>
<div className='col-2' style={{marginRight:"4%"}} >
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(SnumDecimal)} >1/4</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100' onClick={() => fDecimalNumber(THnumDecimal)} >1/3</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(FOnumDecimal)}>1/2</button>

</div>

<div className='col-2' style={{marginRight:"4%"}} onClick={() => fDecimalNumberpoint("*.00")}>
  <button className='btn btn-primary w-100 h-100'>
    <RxDotFilled style={{fontSize:"26px"}}></RxDotFilled>
  </button>
</div>





                          </div>
                          
                          
                          :""}
                       
                        <div className='row mt-3' >
                          <div className='col-4 p-0' >
                            <button className=' btn btn-secondary fontmaaray' id='btn1' value={btnval} onClick={() => setbtnval1(1)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>1</button>
                          </div>
                          <div className='col-4 p-0'  >

                            <button className='btn btn-secondary fontmaaray' id='btn2' value={btnval} onClick={() => setbtnval2(2)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>2</button>

                          </div>

                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn3' onClick={() => setbtnval3(3)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>3</button>

                          </div>

                        </div>
                        <div className='row mt-2' >
                          <div className='col-4 p-0'  >

                            <button className='btn btn-secondary fontmaaray' id='btn4' onClick={() => setbtnval4(4)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>4</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray ' id='btn5' onClick={() => setbtnval5(5)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>5</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn6' onClick={() => setbtnval6(6)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>6</button>
                          </div>

                        </div>
                        <div className='row mt-2' >
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn7' onClick={() => setbtnval7(7)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>7</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn8' onClick={() => setbtnval8(8)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>8</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn9' onClick={() => setbtnval9(9)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>9</button>

                          </div>
                        </div>
                        <div className='row mt-2' >
                          <div className='col-lg-4 col-4 p-0'>
                              <button className='btn btn-warning' style={{height:"100%",width:"83%"}}   onClick={backspacebtn} >
                                <IoBackspaceSharp style={{fontSize:"40px",color:"white"}}> </IoBackspaceSharp>
                                </button>
                          </div>

                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn10' onClick={() => setbtnval10(0)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>0</button>

                          </div>
                          <div className='col-lg-4 col-4 p-0'>
                            <button className='btn btn-danger fontmaaray' style={{ color: "white", cursor: "pointer", width: "82%", height: "100%" }} onClick={closepricepopup}>غلق</button>
                             
                          </div>



                        </div>
                        {itemObj.qty == 0 ? 
                        <div className='row mt-2'>
                        <div className='col-lg-12 col-12 '>
                              <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                              </div>
                        </div>
                        
                        
                         :  
                              <div className='row mt-2'>
                              <div className='col-lg-6 col-7'>
                              <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                              </div>
                              <div className='col-lg-6 col-5'>
                        <button className='btn btn-warning w-100' style={{ color: "#D10000", fontWeight: "bold",height: "60px" }} onClick={() => zeroitem2(itemObj, Section)} >
                              حذف الصنف
                            </button>

                          </div>
                        </div> }
                
                    

                      </div>
                    </div>
                  : ""}


              </div>

            </div>
            <div className='container-fluid'>

              <div className='row'>
                <div className='col-7 d-flex '>
                  <h6 className=' fs-4 fontmaaray ' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
                  <h4 className='   fs-4  fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h4>
                </div>
                <div className='col-5 d-flex' >
                  <h6 className='  fs-4  fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}> كميه </h6>
                  <h6 className='  fs-4 fontmaaray ' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.total.totalqut).toFixed(2)})</h6>
                </div>
              </div>

              <div className='row '>
                <h6 className='col-1 fs-4 fontmaaray' style={{ 'color': props.headerObj.fontcolor }}> الوفر</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>

                <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {Number(props.total.totaldiscount).toFixed(2)}</h6>

              </div>
              <div className='row'>
                <h6 className='col-1 fs-4 fontmaaray' style={{  'color': props.headerObj.fontcolor }}> دليفري</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>
{props.delvVal == undefined?
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.delvVal).toFixed(2)}</h6>



}

              </div>
              <div className='row'>
                <h6 className='col-2 fs-4 fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                <hr className='mt-3 col-7' style={{ 'color': 'white', 'width': '51.5%' }}></hr>
{props.delvVal == undefined? 
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.total.Nettotal+props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:         
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.total.Nettotal + props.delvVal).toFixed(2)}</h6>
}

              </div>
            </div>




          </div>
          
          </div>

                <>
          <div className='row justify-content-center ' id='printablediv' style={{display:"none"}}>
          <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
            <div className='container-fluid bord'>

            <div className="row justify-content-center">
      
      <img src={LogoImageBase64} style={{ "width": "100px","height": "100px" }}></img>
      <div className="col-9" style={{marginTop:"-8%"}}>
        <div className="row justify-content-center headinmobile" >
      <div className="card-title magazine-title " style={{fontSize: "18px", "color":props.headerObj.headerFontColor}}>التاريخ : {moment().format('MMMM Do YYYY, h:mm:ss a')}</div>
    </div>
    </div>
      </div>  
              <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
                <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
                <h1 className='text-light col-10 text-center fontmaaray mt-1'>اوردر</h1>
              </div>
              <div className='row fontmaaray justify-content-center mt-3' id='myTable'>

                <h6 className='col-2 fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>خصم</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>سعر</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>إجمالي</h6>

                {props.OrderItemLst.map(order => (


                  <div className='row justify-content-center fontmaaray position-relative  my-2' >
                    <h6 className='col-1 fontmaaray ' style={{ 'color': props.headerObj.fontcolor,direction:"ltr" }}>{order.qty}</h6>
                    <h6 className='col-11 fontmaaray pe-4  ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>
                    <div className='col-5'></div>
                    <h6 className='col-2 fontmaaray  ' style={{ 'color': props.headerObj.fontcolor }}>{(Number(order.discount).toFixed(2).toLocaleString())}  </h6>
                    <h6 className='col-3 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{Number(order.netprice).toFixed(2).toLocaleString()}</h6>
                    <h6 className='col-2 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{(order.total.toLocaleString())}</h6>
                    <hr className='mt-3 col-9' style={{ 'margin-right': '6%' }}></hr>

                  </div>


                ))}
                {popupprice ?

                  <div className='col-lg-3 col-md-6 col-11 card popup  p-0' id='popup' >
                    <div className='container-fluid p-0'>
                      <div className=' card-header w-100 p-0' style={{ height: "50px" }}>
                              {itemObj.discount == 0 ?
                                <div className='d-flex ' id={itemObj.itemID}>
                                              <div className='col-4 py-1'>
                                    <p className='fontmaaray x3' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{priceinpopupprice} جم</p>
                                  </div>
                                  <div className='col-8 p-0'>
                                 <div className='d-flex'>
<div className='col-8'>
<p className='fontmaaray x1' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl",textAlign:"left" }}>{itemObj.qty} &nbsp; {eqvitem} </p>

</div>
<div className='col-4'>

{itemObj.itemEqvUnts.map((itemObjeqv) => (
<p  id='eledropdown'  style={{borderBottom:"1px solid lightgrey",marginTop:"14%",cursor:"pointer"}}
>{itemObjeqv.eqvName}</p> ))}


</div> 

                                 </div>
                                  </div>
                      
                                </div> :
                                <div className=''>
                                <div className='d-flex justify-content-between'>
                                <div className=' py-1'>
                                  
                                    <p className='fontmaaray x6' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{itemObj.netprice} جم</p>

                                  </div>
                                  <div className=' py-1'>
                                  <p className='fontmaaray x4' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{itemObj.qty}</p>

                                  </div>
                                </div>
 


                                </div>}

                            
                          
                        </div>
                      </div>
                      <div className=' container-fluid p-2'>

                        <div className='row '>
                          <div className='col-12' style={{ fontSize: "20px" }}>
                            <p className='fontmaaray' style={{ textAlign: "center", marginTop: "8px", color: "green", fontWeight: "bold" }}>  {itemObj.itemName} </p>
                          </div>
                        </div>
                        <div className='row' style={{marginTop:"-3%",height:"40px"}}>
                          {array.length > 0?
                            <p className='fontmaaray x2 text-center' style={{ direction:"ltr",fontSize: " 22px", marginTop: "7px", fontWeight: "bold",textAlign:"left" }}> {(array)} &nbsp; {eqvitem} </p>

                          :""}
                          </div>
                          {itemObj.defaultUnt.isDecimal==true?
                          
                            <div className='d-flex' style={{height:"50px"}}>
                          <div className='col-2'>
<button className='btn btn-primary h-100 w-100' onClick={() => fDecimalNumber(FnumDecimal)} >1/8</button>

</div>
<div className='col-2' style={{marginRight:"4%"}} >
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(SnumDecimal)} >1/4</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100' onClick={() => fDecimalNumber(THnumDecimal)} >1/3</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(FOnumDecimal)}>1/2</button>

</div>

<div className='col-2' style={{marginRight:"4%"}} onClick={() => fDecimalNumberpoint("*.00")}>
  <button className='btn btn-primary w-100 h-100'>
    <RxDotFilled style={{fontSize:"26px"}}></RxDotFilled>
  </button>
</div>





                          </div>
                          
                          
                          :""}
                       
                        <div className='row mt-3' >
                          <div className='col-4 p-0' >
                            <button className=' btn btn-secondary fontmaaray' id='btn1' value={btnval} onClick={() => setbtnval1(1)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>1</button>
                          </div>
                          <div className='col-4 p-0'  >

                            <button className='btn btn-secondary fontmaaray' id='btn2' value={btnval} onClick={() => setbtnval2(2)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>2</button>

                          </div>

                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn3' onClick={() => setbtnval3(3)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>3</button>

                          </div>

                        </div>
                        <div className='row mt-2' >
                          <div className='col-4 p-0'  >

                            <button className='btn btn-secondary fontmaaray' id='btn4' onClick={() => setbtnval4(4)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>4</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray ' id='btn5' onClick={() => setbtnval5(5)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>5</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn6' onClick={() => setbtnval6(6)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>6</button>
                          </div>

                        </div>
                        <div className='row mt-2' >
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn7' onClick={() => setbtnval7(7)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>7</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn8' onClick={() => setbtnval8(8)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>8</button>

                          </div>
                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn9' onClick={() => setbtnval9(9)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>9</button>

                          </div>
                        </div>
                        <div className='row mt-2' >
                          <div className='col-lg-4 col-4 p-0'>
                              <button className='btn btn-warning' style={{height:"100%",width:"83%"}}   onClick={backspacebtn} >
                                <IoBackspaceSharp style={{fontSize:"40px",color:"white"}}> </IoBackspaceSharp>
                                </button>
                          </div>

                          <div className='col-4 p-0'  >
                            <button className='btn btn-secondary fontmaaray' id='btn10' onClick={() => setbtnval10(0)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>0</button>

                          </div>
                          <div className='col-lg-4 col-4 p-0'>
                            <button className='btn btn-danger fontmaaray' style={{ color: "white", cursor: "pointer", width: "82%", height: "100%" }} onClick={closepricepopup}>غلق</button>
                             
                          </div>



                        </div>
                        {itemObj.qty == 0 ? 
                        <div className='row mt-2'>
                        <div className='col-lg-12 col-12 '>
                              <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                              </div>
                        </div>
                        
                        
                         :  
                              <div className='row mt-2'>
                              <div className='col-lg-6 col-7'>
                              <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                              </div>
                              <div className='col-lg-6 col-5'>
                        <button className='btn btn-warning w-100' style={{ color: "#D10000", fontWeight: "bold",height: "60px" }} onClick={() => zeroitem2(itemObj, Section)} >
                              حذف الصنف
                            </button>

                          </div>
                        </div> }
                
                    

                      </div>
                    </div>
                  : ""}

              </div>

            </div>
            <div className='container-fluid'>

              <div className='row'>
                <div className='col-7 d-flex '>
                  <h6 className=' fs-4 fontmaaray ' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
                  <h4 className='   fs-4  fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h4>
                </div>
                <div className='col-5 d-flex' >
                  <h6 className='  fs-4  fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}> كميه </h6>
                  <h6 className='  fs-4 fontmaaray ' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.total.totalqut).toFixed(2)})</h6>
                </div>
              </div>

              <div className='row '>
                <h6 className='col-1 fs-4 fontmaaray' style={{ 'color': props.headerObj.fontcolor }}> الوفر</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>

                <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {Number(props.total.totaldiscount).toFixed(2)}</h6>

              </div>
              <div className='row'>
                <h6 className='col-1 fs-4 fontmaaray' style={{  'color': props.headerObj.fontcolor }}> دليفري</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>
{props.delvVal == undefined?
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.delvVal).toFixed(2)}</h6>



}

              </div>
              <div className='row'>
                <h6 className='col-2 fs-4 fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                <hr className='mt-3 col-7' style={{ 'color': 'white', 'width': '51.5%' }}></hr>
{props.delvVal == undefined? 
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.total.Nettotal+props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:         
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.total.Nettotal + props.delvVal).toFixed(2)}</h6>
}

              </div>
            </div>
          </div>
          </div>
</>

          <div className='row justify-content-center btnprientdesktop'>
<div className='col-lg-3 col-12'>
<button className='btn  w-100 text-white fontmaaray' style={{background:"#FFC000",height:"54px",fontSize:"27px"}} onClick={createScreenshotPDF}>طباعه</button>
</div>
</div>
<div className='row justify-content-center btnprientmobile'>
<div className='col-lg-3 col-12'>
<button className='btn  w-100 text-white fontmaaray' style={{background:"#FFC000",height:"54px",fontSize:"27px"}} onClick={createScreenshotPDFMob}>طباعه</button>
</div>
</div>

      </div>
      <div className='container-fluid'>
        <div className='row justify-content-center ' id='orderprint2'>
          <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
            <div className='container-fluid bord'>

              <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
                <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
                <h1 className='text-light col-10 text-center fontmaaray mt-1'>اوردر</h1>
              </div>
              {/*table of order item */}
              <div className='row fontmaaray justify-content-center mt-3' id='myTable'>

                <h6 className='col-1 fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                <h6 className='col-4  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>خصم</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>سعر</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>إجمالي</h6>

                {props.OrderItemLst.map(order => (


                  <div className='row justify-content-center fontmaaray position-relative  my-2' >
                    <button className='btn btn-danger d-block d-md-none py-0 position-absolute' onClick={() => showpopupprice(order)} style={{ zIndex: "5", left: "5px", top: "-5px", width: "auto" }}><EditIcon sx={{ fontSize: "1rem" }}></EditIcon></button>
                    <h6 className='col-1 fontmaaray ' style={{ 'color': props.headerObj.fontcolor,direction:"ltr" }}>{order.qty}</h6>
                    <h6 className='col-11 fontmaaray pe-4  ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>
                    <div className='col-5'></div>
                    <h6 className='col-2 fontmaaray  ' style={{ 'color': props.headerObj.fontcolor }}>{(Number(order.discount).toFixed(2).toLocaleString())}  </h6>
                    <h6 className='col-3 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{(Number(order.netprice).toFixed(2).toLocaleString())}</h6>
                    <h6 className='col-2 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{(order.total.toLocaleString())}</h6>
                    <hr className='mt-3 col-9' style={{ 'margin-right': '6%' }}></hr>

                  </div>


                ))}
             



              </div>

            </div>
            <div className='container-fluid'>

              <div className='row'>
                <div className='col-7 d-flex '>
                  <h6 className=' fs-4 fontmaaray ' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
                  <h4 className='   fs-4  fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h4>
                </div>
                <div className='col-5 d-flex' >
                  <h6 className='  fs-4  fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}> كميه </h6>
                  <h6 className='  fs-4 fontmaaray ' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.total.totalqut).toFixed(2)})</h6>
                </div>
              </div>

              <div className='row '>
                <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> الوفر</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>

                <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {Number(props.total.totaldiscount).toFixed(2)}</h6>

              </div>
              <div className='row'>
                <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> دليفري</h6>
                <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>
{props.delvVal == undefined?
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.delvVal).toFixed(2)}</h6>



}

              </div>
              <div className='row'>
                <h6 className='col-2 fs-4 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                <hr className='mt-3 col-7' style={{ 'color': 'white', 'width': '51.5%' }}></hr>
{props.delvVal == undefined? 
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.total.Nettotal+props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:         
       <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.total.Nettotal + props.delvVal).toFixed(2)}</h6>
}

              </div>
            </div>




          </div></div>


      </div>
      <div></div>

{/*  */}

    
{props.orderpoppup==true?
<div className='mainoforderpopup'>
<div class="col-lg-11 col-md-6 col-12 card  p-0 popuporder  " >
 
    
 <div class=" card-header w-100 p-0" style={{display:"flex"}}>
   <button type="button" class="close btn btn-danger " onClick={props.hideorderpopup}>
     <span className='text-white fs-4' aria-hidden="true">&times;</span>
   </button>
 </div>
 
 <div className='container-fluid containerpouporder' >
   <div className='row justify-content-center '>
     <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
       <div className='container-fluid bord'>

         <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
           <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
           <h1 className='text-light col-10 text-center fontmaaray mt-1'>اوردر</h1>
         </div>
         {/*table of order item */}
         <div className='row fontmaaray justify-content-center mt-3 ' id='myTable'>

           <h6 className='col-2 fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
           <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
           <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>خصم</h6>
           <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>سعر</h6>
           <h6 className='col-2 padinmob fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>إجمالي</h6>

           {props.OrderItemLst.map(order => (


             <div className='row tableinpopup fontmaaray position-relative  my-2' >
              <button id="printButton" className='btn btn-danger d-block d-md-none py-0 position-absolute' onClick={() => showpopupprice(order)} style={{ zIndex: "5", left: "5px", top: "-5px", width: "auto" }}><EditIcon sx={{ fontSize: "1rem" }}></EditIcon></button>
               <h6 className='col-lg-1 col-2 fontmaaray ' style={{ 'color': props.headerObj.fontcolor,direction:"ltr" }}>{order.qty}</h6>
               <h6 className='col-lg-11 col-12 fontmaaray pe-4  ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>
               <div className='col-5'></div>
               <h6 className='col-2 fontmaaray  ' style={{ 'color': props.headerObj.fontcolor }}>{(Number(order.discount).toFixed(2).toLocaleString())}  </h6>
               <h6 className='col-3 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{Number(order.netprice).toFixed(2).toLocaleString()}</h6>
               <h6 className='col-2 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{(order.total.toLocaleString())}</h6>
               <hr className='mt-3 col-9' style={{ 'margin-right': '6%' }}></hr>

             </div>


           ))}
           {popupprice ?

             <div className='col-lg-3 col-md-6 col-11 card popup  p-0' id='popup' >
               <div className='container-fluid p-0'>
                 <div className=' card-header w-100 p-0' style={{ height: "50px" }}>
                         {itemObj.discount == 0 ?
                           <div className='d-flex ' id={itemObj.itemID}>
                                         <div className='col-4 py-1'>
                               <p className='fontmaaray x3' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{priceinpopupprice} جم</p>
                             </div>
                             <div className='col-8 p-0'>
                            <div className='d-flex'>
<div className='col-8'>
<p className='fontmaaray x1' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl",textAlign:"left" }}>{itemObj.qty} &nbsp; {eqvitem} </p>

</div>
<div className='col-4'>

{itemObj.itemEqvUnts.map((itemObjeqv) => (
<p  id='eledropdown'  style={{borderBottom:"1px solid lightgrey",marginTop:"14%",cursor:"pointer"}}
>{itemObjeqv.eqvName}</p> ))}


</div> 

                            </div>
                        




                               
                             
                             </div>
                 
                           </div> :
                           <div className=''>
                           <div className='d-flex justify-content-between'>
                           <div className=' py-1'>
                             
                               <p className='fontmaaray x6' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{itemObj.netprice} جم</p>

                             </div>
                             <div className=' py-1'>
                             <p className='fontmaaray x4' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{itemObj.qty}</p>

                             </div>
                           </div>



                           </div>}

                       
                     
                   </div>
                 </div>
                 <div className=' container-fluid p-2'>

                   <div className='row '>
                     <div className='col-12' style={{ fontSize: "20px" }}>
                       <p className='fontmaaray' style={{ textAlign: "center", marginTop: "8px", color: "green", fontWeight: "bold" }}>  {itemObj.itemName} </p>
                     </div>
                   </div>
                   <div className='row' style={{marginTop:"-3%",height:"40px"}}>
                     {array.length > 0?
                       <p className='fontmaaray x2 text-center' style={{ direction:"ltr",fontSize: " 22px", marginTop: "7px", fontWeight: "bold",textAlign:"left" }}> {(array)} &nbsp; {eqvitem} </p>

                     :""}
                     </div>
                     {itemObj.defaultUnt.isDecimal==true?
                     
                       <div className='d-flex' style={{height:"50px"}}>
                     <div className='col-2'>
<button className='btn btn-primary h-100 w-100' onClick={() => fDecimalNumber(FnumDecimal)} >1/8</button>

</div>
<div className='col-2' style={{marginRight:"4%"}} >
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(SnumDecimal)} >1/4</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100' onClick={() => fDecimalNumber(THnumDecimal)} >1/3</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(FOnumDecimal)}>1/2</button>

</div>

<div className='col-2' style={{marginRight:"4%"}} onClick={() => fDecimalNumberpoint("*.00")}>
<button className='btn btn-primary w-100 h-100'>
<RxDotFilled style={{fontSize:"26px"}}></RxDotFilled>
</button>
</div>





                     </div>
                     
                     
                     :""}
                  
                   <div className='row mt-3' >
                     <div className='col-4 p-0' >
                       <button className=' btn btn-secondary fontmaaray' id='btn1' value={btnval} onClick={() => setbtnval1(1)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>1</button>
                     </div>
                     <div className='col-4 p-0'  >

                       <button className='btn btn-secondary fontmaaray' id='btn2' value={btnval} onClick={() => setbtnval2(2)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>2</button>

                     </div>

                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn3' onClick={() => setbtnval3(3)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>3</button>

                     </div>

                   </div>
                   <div className='row mt-2' >
                     <div className='col-4 p-0'  >

                       <button className='btn btn-secondary fontmaaray' id='btn4' onClick={() => setbtnval4(4)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>4</button>

                     </div>
                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray ' id='btn5' onClick={() => setbtnval5(5)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>5</button>

                     </div>
                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn6' onClick={() => setbtnval6(6)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>6</button>
                     </div>

                   </div>
                   <div className='row mt-2' >
                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn7' onClick={() => setbtnval7(7)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>7</button>

                     </div>
                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn8' onClick={() => setbtnval8(8)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>8</button>

                     </div>
                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn9' onClick={() => setbtnval9(9)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>9</button>

                     </div>
                   </div>
                   <div className='row mt-2' >
                     <div className='col-lg-4 col-4 p-0'>
                         <button className='btn btn-warning' style={{height:"100%",width:"83%"}}   onClick={backspacebtn} >
                           <IoBackspaceSharp style={{fontSize:"40px",color:"white"}}> </IoBackspaceSharp>
                           </button>
                     </div>

                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn10' onClick={() => setbtnval10(0)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>0</button>

                     </div>
                     <div className='col-lg-4 col-4 p-0'>
                       <button className='btn btn-danger fontmaaray' style={{ color: "white", cursor: "pointer", width: "82%", height: "100%" }} onClick={closepricepopup}>غلق</button>
                        
                     </div>



                   </div>
                   {itemObj.qty == 0 ? 
                   <div className='row mt-2'>
                   <div className='col-lg-12 col-12 '>
                         <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                         </div>
                   </div>
                   
                   
                    :  
                         <div className='row mt-2'>
                         <div className='col-lg-6 col-7'>
                         <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                         </div>
                         <div className='col-lg-6 col-5'>
                   <button className='btn btn-warning w-100' style={{ color: "#D10000", fontWeight: "bold",height: "60px" }} onClick={() => zeroitem2(itemObj, Section)} >
                         حذف الصنف
                       </button>

                     </div>
                   </div> }
           
               

                 </div>
               </div>
             : ""}

           {/*total of order  */}


         </div>

       </div>
       <div className='container-fluid'>

         <div className='row'>
           <div className='col-lg-7 col-7 d-flex '>
             <h6 className=' fs-4 fontmaaray ' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
             <h4 className='   fs-4  fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h4>
           </div>
           <div className='col-lg-5 col-7 d-flex' >
             <h6 className='  fs-4  fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}> كميه </h6>
             <h6 className='  fs-4 fontmaaray ' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.total.totalqut).toFixed(2)})</h6>
           </div>
         </div>

         <div className='row '>
           <h6 className='col-lg-1 col-5 fs-4 fontmaaray totalltext' style={{ 'color': props.headerObj.fontcolor }}> الوفر</h6>
           <hr className='mt-3 col-10 itemhidden' style={{ 'width': '60%', 'color': 'white' }}></hr>

           <h6 className='col-lg-1 col-5 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {Number(props.total.totaldiscount).toFixed(2)}</h6>

         </div>
         <div className='row'>
           <h6 className='col-lg-1 col-5 fs-4 fontmaaray totalltext' style={{  'color': props.headerObj.fontcolor }}> دليفري</h6>
           <hr className='mt-3 col-10 itemhidden'  style={{ 'width': '60%', 'color': 'white' }}></hr>
{props.delvVal == undefined?
<h6 className='col-lg-1  col-5 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:
<h6 className='col-lg-1 col-5 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.delvVal).toFixed(2)}</h6>



}

         </div>
         <div className='row'>
           <h6 className='col-lg-2 col-5 fs-4 fontmaaray totalltext' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
           <hr className='mt-3 col-7 itemhidden' style={{ 'color': 'white', 'width': '51.5%' }}></hr>
{props.delvVal == undefined? 
  <h6 className='col-lg-1 col-6 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.total.Nettotal+props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:         
  <h6 className='col-lg-1 col-5 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.total.Nettotal + props.delvVal).toFixed(2)}</h6>
}

         </div>
       </div>




     </div>
     
     </div>

           {/* Mobile PDF Print */}
           <>
     <div className='row justify-content-center ' id='printablediv' style={{display:"none"}}>
     <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
       <div className='container-fluid bord'>

       <div className="row justify-content-center">
 
 <img src={LogoImageBase64} style={{ "width": "100px","height": "100px" }}></img>
 <div className="col-9" style={{marginTop:"-8%"}}>
   <div className="row justify-content-center headinmobile" >
 <div className="card-title magazine-title " style={{fontSize: "18px", "color":props.headerObj.headerFontColor}}>التاريخ : {moment().format('MMMM Do YYYY, h:mm:ss a')}</div>
</div>
</div>
 </div>  
         <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
           <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
           <h1 className='text-light col-10 text-center fontmaaray mt-1'>اوردر</h1>
         </div>
         {/*table of order item */}
         <div className='row fontmaaray justify-content-center mt-3' id='myTable'>

           <h6 className='col-2 fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
           <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
           <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>خصم</h6>
           <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>سعر</h6>
           <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>إجمالي</h6>

           {/*maping from list to show order details [name, quntity,total discount,total ] */}
           {props.OrderItemLst.map(order => (


             <div className='row justify-content-center fontmaaray position-relative  my-2' >
               <h6 className='col-1 fontmaaray ' style={{ 'color': props.headerObj.fontcolor,direction:"ltr" }}>{order.qty}</h6>
               <h6 className='col-11 fontmaaray pe-4  ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>
               <div className='col-5'></div>
               <h6 className='col-2 fontmaaray  ' style={{ 'color': props.headerObj.fontcolor }}>{(Number(order.discount).toFixed(2).toLocaleString())}  </h6>
               <h6 className='col-3 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{Number(order.netprice).toFixed(2).toLocaleString()}</h6>
               <h6 className='col-2 fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{(order.total.toLocaleString())}</h6>
               <hr className='mt-3 col-9' style={{ 'margin-right': '6%' }}></hr>

             </div>


           ))}

           {popupprice ?

             <div className='col-lg-3 col-md-6 col-11 card popup  p-0' id='popup' >
               <div className='container-fluid p-0'>
                 <div className=' card-header w-100 p-0' style={{ height: "50px" }}>
                         {itemObj.discount == 0 ?
                           <div className='d-flex ' id={itemObj.itemID}>
                                         <div className='col-4 py-1'>
                               <p className='fontmaaray x3' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{priceinpopupprice} جم</p>
                             </div>
                             <div className='col-8 p-0'>
                            <div className='d-flex'>
<div className='col-8'>
<p className='fontmaaray x1' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl",textAlign:"left" }}>{itemObj.qty} &nbsp; {eqvitem} </p>

</div>
<div className='col-4'>

{itemObj.itemEqvUnts.map((itemObjeqv) => (
<p  id='eledropdown'  style={{borderBottom:"1px solid lightgrey",marginTop:"14%",cursor:"pointer"}}
>{itemObjeqv.eqvName}</p> ))}


</div> 

                            </div>
                        




                               
                             
                                {/* <p className='fontmaaray x1' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{itemObj.qty}</p> */}
                             </div>
                 
                           </div> :
                           <div className=''>
                           <div className='d-flex justify-content-between'>
                           <div className=' py-1'>
                             
                               <p className='fontmaaray x6' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{itemObj.netprice} جم</p>

                             </div>
                             <div className=' py-1'>
                             <p className='fontmaaray x4' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{itemObj.qty}</p>

                             </div>
                           </div>



                           </div>}

                       
                     
                   </div>
                 </div>
                 <div className=' container-fluid p-2'>

                   <div className='row '>
                     <div className='col-12' style={{ fontSize: "20px" }}>
                       <p className='fontmaaray' style={{ textAlign: "center", marginTop: "8px", color: "green", fontWeight: "bold" }}>  {itemObj.itemName} </p>
                     </div>
                   </div>
                   <div className='row' style={{marginTop:"-3%",height:"40px"}}>
                     {array.length > 0?
                       <p className='fontmaaray x2 text-center' style={{ direction:"ltr",fontSize: " 22px", marginTop: "7px", fontWeight: "bold",textAlign:"left" }}> {(array)} &nbsp; {eqvitem} </p>

                     :""}
                     </div>
                     {itemObj.defaultUnt.isDecimal==true?
                     
                       <div className='d-flex' style={{height:"50px"}}>
                     <div className='col-2'>
<button className='btn btn-primary h-100 w-100' onClick={() => fDecimalNumber(FnumDecimal)} >1/8</button>

</div>
<div className='col-2' style={{marginRight:"4%"}} >
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(SnumDecimal)} >1/4</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100' onClick={() => fDecimalNumber(THnumDecimal)} >1/3</button>

</div>
<div className='col-2' style={{marginRight:"4%"}}>
<button className='btn btn-primary w-100 h-100'  onClick={() => fDecimalNumber(FOnumDecimal)}>1/2</button>

</div>

<div className='col-2' style={{marginRight:"4%"}} onClick={() => fDecimalNumberpoint("*.00")}>
<button className='btn btn-primary w-100 h-100'>
<RxDotFilled style={{fontSize:"26px"}}></RxDotFilled>
</button>
</div>





                     </div>
                     
                     
                     :""}
                  
                   <div className='row mt-3' >
                     <div className='col-4 p-0' >
                       <button className=' btn btn-secondary fontmaaray' id='btn1' value={btnval} onClick={() => setbtnval1(1)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>1</button>
                     </div>
                     <div className='col-4 p-0'  >

                       <button className='btn btn-secondary fontmaaray' id='btn2' value={btnval} onClick={() => setbtnval2(2)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>2</button>

                     </div>

                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn3' onClick={() => setbtnval3(3)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>3</button>

                     </div>

                   </div>
                   <div className='row mt-2' >
                     <div className='col-4 p-0'  >

                       <button className='btn btn-secondary fontmaaray' id='btn4' onClick={() => setbtnval4(4)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>4</button>

                     </div>
                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray ' id='btn5' onClick={() => setbtnval5(5)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>5</button>

                     </div>
                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn6' onClick={() => setbtnval6(6)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>6</button>
                     </div>

                   </div>
                   <div className='row mt-2' >
                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn7' onClick={() => setbtnval7(7)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>7</button>

                     </div>
                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn8' onClick={() => setbtnval8(8)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>8</button>

                     </div>
                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn9' onClick={() => setbtnval9(9)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>9</button>

                     </div>
                   </div>
                   <div className='row mt-2' >
                     <div className='col-lg-4 col-4 p-0'>
                         <button className='btn btn-warning' style={{height:"100%",width:"83%"}}   onClick={backspacebtn} >
                           <IoBackspaceSharp style={{fontSize:"40px",color:"white"}}> </IoBackspaceSharp>
                           </button>
                     </div>

                     <div className='col-4 p-0'  >
                       <button className='btn btn-secondary fontmaaray' id='btn10' onClick={() => setbtnval10(0)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>0</button>

                     </div>
                     <div className='col-lg-4 col-4 p-0'>
                       <button className='btn btn-danger fontmaaray' style={{ color: "white", cursor: "pointer", width: "82%", height: "100%" }} onClick={closepricepopup}>غلق</button>
                        
                     </div>



                   </div>
                   {itemObj.qty == 0 ? 
                   <div className='row mt-2'>
                   <div className='col-lg-12 col-12 '>
                         <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                         </div>
                   </div>
                   
                   
                    :  
                         <div className='row mt-2'>
                         <div className='col-lg-6 col-7'>
                         <button className='btn  btn-success fontmaaray' style={{ backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%", height: "60px" }} onClick={() => showarrayleng(itemObj)}>موافق</button>

                         </div>
                         <div className='col-lg-6 col-5'>
                   <button className='btn btn-warning w-100' style={{ color: "#D10000", fontWeight: "bold",height: "60px" }} onClick={() => zeroitem2(itemObj, Section)} >
                         حذف الصنف
                       </button>

                     </div>
                   </div> }
           
               

                 </div>
               </div>
             : ""}

           {/*total of order  */}


         </div>

       </div>
       <div className='container-fluid'>

         <div className='row'>
           <div className='col-7 d-flex '>
             <h6 className=' fs-4 fontmaaray ' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
             <h4 className='   fs-4  fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h4>
           </div>
           <div className='col-5 d-flex' >
             <h6 className='  fs-4  fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}> كميه </h6>
             <h6 className='  fs-4 fontmaaray ' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>({Number(props.total.totalqut).toFixed(2)})</h6>
           </div>
         </div>

         <div className='row '>
           <h6 className='col-1 fs-4 fontmaaray' style={{ 'color': props.headerObj.fontcolor }}> الوفر</h6>
           <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>

           <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {Number(props.total.totaldiscount).toFixed(2)}</h6>

         </div>
         <div className='row'>
           <h6 className='col-1 fs-4 fontmaaray' style={{  'color': props.headerObj.fontcolor }}> دليفري</h6>
           <hr className='mt-3 col-10' style={{ 'width': '60%', 'color': 'white' }}></hr>
{props.delvVal == undefined?
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:
<h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.delvVal).toFixed(2)}</h6>



}

         </div>
         <div className='row'>
           <h6 className='col-2 fs-4 fontmaaray' style={{  'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
           <hr className='mt-3 col-7' style={{ 'color': 'white', 'width': '51.5%' }}></hr>
{props.delvVal == undefined? 
  <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.total.Nettotal+props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

:         
  <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.total.Nettotal + props.delvVal).toFixed(2)}</h6>
}

         </div>
       </div>




     </div>
     </div>
</>

     <div className='row justify-content-center btnprientdesktop'>
<div className='col-lg-3 col-12'>
<button className='btn  w-100 text-white fontmaaray' style={{background:"#FFC000",height:"54px",fontSize:"27px"}} onClick={createScreenshotPDF}>طباعه</button>
</div>
</div>
<div className='row justify-content-center btnprientmobile'>
<div className='col-lg-3 col-12'>
<button className='btn  w-100 text-white fontmaaray' style={{background:"#FFC000",height:"54px",fontSize:"27px"}} onClick={createScreenshotPDFMob}>طباعه</button>
</div>
</div>
{/* <div className='row justify-content-center btnprientmobile'>
<div className='col-lg-3 col-12'>
<button className='btn  w-100 text-white fontmaaray' style={{background:"#FFC000",height:"54px",fontSize:"27px"}} onClick={createScreenshotPDFMob}>طباعه</button>
</div>
</div> */}
 </div>




</div>
  
</div>
:""}






    </div>
  );

};

export default BasicExample;
