import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import './Item.css';
import axios from "axios";
import Item from './Item.js'

function InfiniteScrolling(props) {

    const fetchMoreData = () => {
        let pageNo = props.pageNo + 1
        props.setPage(pageNo)
        if(pageNo > 1){
           return axios.get('https://tijarymagazineapis.azurewebsites.net/api/MagazineHead/GetPagedView/' + props.magazineID+"/" + props.Section[0].sectionID + "/"+pageNo)
           .then((response) => {
            console.log("fetchMoreData response.data",response.data)
            const updatedSection = [...props.Section];
            updatedSection.forEach((section) => {
                section.items = section.items.concat(response.data[0].items)
                });

            props.setSection(updatedSection);
          console.log("fetchMoreData props.Section.items",props.Section.items)
        })
        }
        else{
          return axios.get('https://tijarymagazineapis.azurewebsites.net/api/MagazineHead/GetPagedView/' + props.magazineID+"/" + props.Section[0].sectionID+"/"+pageNo)
          .then((response) => {
            props.setSection(response.data)
          })
        }  
          };
    

  return (
    <div >
          {props.Section &&
            <div>
                <div className='my-1 mt-2'>
                  <img className='w-100' style={{borderRadius:"6px"}} src={props.Section[0].sectionBanner} />
                </div>
                <div className='row  g-2 itemmargin'>
                  { props.Section[0].items.map((itemObj, index) => (
                    <Item showItemDtls = {props.showItemDtls} headerObj = {props.headerObj} itemObj = {itemObj} 
                    changequt = {props.changequt} btnonclick = {props.btnonclick} 
                    showpopuppriceitem = {props.showpopuppriceitem}/>
                  ))}
                </div>
                { props.Section[0].pagesCount > props.pageNo &&
                  <button  style={{fontSize:'22px',width:'100%', 'z-index': '100',marginTop:'2%','color': props.headerObj.buttonColor
                  ,marginBottom:'2%'  }} 
                className='btn fontmaaray'
                 onClick={() => fetchMoreData()} id='inputcheckqut'>المزيد... </button>
                }
           
              </div>
          }
  </div>
  );

};

export default InfiniteScrolling;
