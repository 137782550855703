import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import './ItemDtlsPopUp.css';
import Carousel from 'react-bootstrap/Carousel';

function ItemDtlsPopUp(props) {

  const isValidUrl = urlString=> {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
  }

    return (
      <div className='col-8 card scrollable' id='ItemDtlsPopUp' style={{height:"100%"}}>
<div className='container-fluid'>
<div className='row p-0' style={{margin:"0px",height: "50px",textAlign:"right",alignItems:"start",justifyContent:"center"}}>
<p className='fontmaaray' style={{margin:"0px",color:"black",fontSize:"24px"}}> 
 {props.ItemObj.sectionName} 
 </p>

</div>
   <div className="container-fluid" >
{/*maping to api to slider */}
{props.ItemObj.magazineSectionItemsSubImgsView && props.ItemObj.magazineSectionItemsSubImgsView.length > 0 && <div>       
  
  <div className="row justify-content-center"  style={{'min-height':'120px','height':'100%'}}>
      <div className="col-12 ">
    <Carousel className="carousel"
    interval={4000}
    adaptiveHeight={false}
     >
      {props.ItemObj.magazineSectionItemsSubImgsView.map((Slider, index) => (
 <Carousel.Item className="slide" >
 <img
   className="w-100 "
   src={isValidUrl(Slider.img)?Slider.img:(Slider.img)}
   alt="First slide"
   style={{'height':'120px','width':'90%'}}
 />
 
</Carousel.Item>



      ) )}
     
    </Carousel>
    </div>
    </div>
     </div>}
    
     <div className='row p-0' style={{textAlign:"center",alignItems:"center",justifyContent:"center"}}>
<p className='fontmaaray' style={{margin:"0px",color:"black",fontSize:"24px"}}> 
 {props.ItemObj.itemName} 
 </p>
</div>
<br/>
<div className='row p-0' style={{textAlign:"center",alignItems:"center",justifyContent:"center",textAlign:"justify"}}>
<p className='fontmaaray' style={{margin:"0px",color:"black",fontSize:"20px"}}> 
 {props.ItemObj.itemDescription} 
 </p>
</div>

<div className="center-line" ></div>


          
          <div className="fontmaaray divbody mt-0"  dir='rtl'>
  
{props.ItemObj.itemDiscountAmount == 0 ? ""
          : <><span className='Discount '>-%</span> 
          <h4 className='Discount' > 
          {props.ItemObj.itemDiscountPercentage}</h4><span className='Discount '>وفر</span> </>}
          <h4 className='price'> 
           {props.ItemObj.itemDiscountedPrice}</h4><span className='price '>جم</span> 
        </div>
        <div className="fontmaaray divbody mt-0"  dir='rtl'>
        <h4 className='Discount' style={{color:"black"}}> سعر القائمة:</h4>
        <s style={{display:"flex"}}><h4 className='Discount' style={{color:"black"}}> 
             {props.ItemObj.itemSalesPrice}</h4><span className='Discount ' style={{color:"black"}}>جم</span></s>
          </div>
          <div className='row justify-content-center' >

<div className='col-4 col-lg-5'  onClick={() => props.setshowItemDtlsPopup(false)}>
<button className='btn btn-danger w-100 fontmaaray'>إغلاق</button>
</div>

</div>
    </div>
</div>
</div>
      );
            
    };
   
  export default  ItemDtlsPopUp;
  