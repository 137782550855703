import './App.css';
import Header from './nav/header';
import BasicExample from './container/container';
import UncontrolledExample from './carsoul/slider';
import {useState,} from 'react';
import RecordView from './recorder/recorder';
import Formuser from './form-user/form';
import React from 'react';
import uploadFileToBlob, { isStorageConfigured } from './recorder/azureBlob';
import Footer from './footer/footer';
import { useEffect } from "react";
import axios from "axios";
import Nomagazine from './nomagazine/nomagazine';
import { useRef } from "react";
import Swal from "sweetalert2";
import ReactGA from "react-ga4";
import ItemDtlsPopUp from './ItemDtlsPopUp/ItemDtlsPopUp.js'
import FooterNav from './Components/FooterNav.js';
import myVideo from './video.mp4';



function App() {
  const objtotal = {
    'totalqut': 0,
    'totalpricedel': 0,
    'totaldiscount': 0,
    'totalprice': 0,
    'Nettotal': 0
  }
  const merchantMagazineId = 7
  const safeDocument = typeof document !== 'undefined' ? document : {};
  const { body } = safeDocument;
  
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;

  const[regionValefun,setregionValefun]=useState()
  const [total, settotal] = useState(objtotal);
  const[Recfile,setRecfile]=useState()
  const[CommentNomagazine,setCommentNomagazine]=useState()
  const[elementprint,setelementprint]=useState(false)
  const[saleId,setsaleId]=useState()
  const[saleIdman,setsaleIdman]=useState()
  const[delvVal,setdelvVal]=useState()
  const[magazineID,setmagazineID]=useState()
  const [header, setHeader] = useState();
  const [comment, setcomment] = useState();
  const [orderpoppup, setorderpoppup] = useState(false)
  // const [viewslider, setviewslider] = useState(false);
  const[showItemDtlsPopup,setshowItemDtlsPopup]=useState(false)
  const[ItemDtlsObj,setItemDtlsObj]=useState()
////////////////////to upload file//////////////////////
const [fileuplod,setFileuplod]=useState()
const[mediaBlobUrl,setmediaBlobUrl]=useState('')
    const [blobList, setBlobList] = useState([]);
const [orderlist, setorderlist] = React.useState([]);   
// current file to upload into container
const [fileSelected, setFileSelected] = useState(null);
// UI/form management
const [uploading, setUploading] = useState(false);
const [inputKey, setInputKey] = useState(Math.random().toString(36).split());
const[showagain,setshowagain]=useState(true)

// const TRACKING_ID="G-WHLEKW376Q"
//   ReactGA.initialize(TRACKING_ID);
//   ReactGA.send({ hitType: "pageview", page: "dp.tijary.store", title: "tijary" });
 
// //  const TRACKING_ID = "UA-12341234-1"; // YOUR_OWN_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
function videoload(){
  Swal.fire({
   
    // icon: 'info',
    html:
      '<video width="500" height="315" controls autoplay> <source src='+myVideo+' type="video/mp4"  />Your browser does not support the video tag.</video>'
      +'<label class="fontmaaray">عدم اظهار الفيديو مره اخري</label>'

      +'<input type="checkbox" id="cheackinvideo">',
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:
      'موافق',


  }).then((result) => {
    if (result.isConfirmed && result.value === true) {
      const x=document.getElementById("cheackinvideo").checked
      setshowagain(true)
      localStorage.setItem('showagain', true)
      document.getElementById("cheackinvideo").checked=true


    }
  else{
    localStorage.setItem('showagain', false)


  }
})

}

  useEffect(()  => {

    if(localStorage.getItem('showagain')== "false"){
      videoload()
    }  
 
    
    fetchIdmagazine(merchantMagazineId);
   },[])
   
   /**
    * Fetches the ID of a magazine for a given merchant ID.
    *
    * @param {number} merchantId - The ID of the merchant.
    * @return {Promise} A Promise that resolves to the ID of the magazine.
    */
   const fetchIdmagazine = (merchantId) => {
    return axios.get("https://tijarymagazineapis.azurewebsites.net/GetLastMagazineIDwithMagazineMerhanctID/"+merchantId)
          .then((response) => {
           fetchHeader(response.data.magazineID);
           setmagazineID(response.data.magazineID)
       
            });
  }

  
  const fetchHeader = (magazineID) => {
    
    return axios.get('https://tijarymagazineapis.azurewebsites.net/api/MagazineHead/GetHome/'+magazineID)
          .then((response) => {
            setHeader(response.data);
            console.log("response.data",response.data)
          })
  }


  const onFileUpload = async (pathName) => {
    // prepare UI
    setUploading(true);
    // *** UPLOAD TO AZURE STORAGE ***
    const blobsInContainer = await uploadFileToBlob(pathName);
    // prepare UI for results
    setBlobList(blobsInContainer);
    // reset state/form
    setFileSelected(pathName);
    setUploading(false);
    setInputKey(Math.random().toString(36));
  }

  function showpopuporder(){
    setorderpoppup(true)
    
if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = 'relative'; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    // body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  }

  function hideorderpopup(){
    setorderpoppup(false)
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;

  }

  /////////////////////////convert blob media ////////////////
  const  converttofile =  async(e) => {
    const audioBlobss = await fetch(mediaBlobUrl).then((r) => r.blob());
    const audioFile = new File([audioBlobss], 'voice.wav', { type: 'audio/wav'});

    onFileUpload(audioFile)
  }
  const[filterpopup,setfilterpopup] =useState(false)
 
function showfilterpopup(){
  document.body.style.overflow = "hidden"

  const bottomnavigation = document.getElementById('bottomnavigation');
  bottomnavigation.style.display="none"
  if(filterpopup==true){
    setfilterpopup(false)
  }
  else{
  setfilterpopup(true)
  }

}
function closefilterpoup(){
  document.body.style.overflowY = "scroll"

  const bottomnavigation = document.getElementById('bottomnavigation');
  bottomnavigation.style.display="flex"
  setfilterpopup(false)
}
  
  return (
    <div className="App" style={{width: "100%",marginLeft:"0%"    }}  >
       {header  ?  <div style={{"background":header.backGround}}>
    {elementprint==true?  "" : <div id='scrolling-map'>
    
    <header className="App-header"  id='Headop'>
        <Header headerObj={header}> </Header>
      </header>
      {console.log("header.sliders",header.sliders)}
   {header.sliders.length==0?
   ""
   
   : <UncontrolledExample id="sliderOp" headerObj={header} ></UncontrolledExample>}

{showItemDtlsPopup == true ?
      <ItemDtlsPopUp ItemObj = {ItemDtlsObj} setshowItemDtlsPopup = {setshowItemDtlsPopup}/>
      :<>
      <BasicExample settotal={settotal}
      total = {total}
      orderpoppup={orderpoppup}
      setorderpoppup={setorderpoppup}
      hideorderpopup = {hideorderpopup}
       setshowItemDtlsPopup={setshowItemDtlsPopup} 
       setItemDtlsObj={setItemDtlsObj} OrderItemLst = {orderlist}
    SetOrderItemLst = {setorderlist}
    headerObj={header}
    setsaleIdP={setsaleId}
    saleIdVP={saleId}
    saleIdmanVP={saleIdman}
    setsaleIdmanP={setsaleIdman}
    delvVal={delvVal}
    magazineID={magazineID}
    filterpopup={filterpopup}
    closefilterpoup={closefilterpoup} 
    showfilterpopup={showfilterpopup}
></BasicExample>
<div className='mt-4'></div>

<RecordView  id="recordOp"
OnmediaBlobUrlChange={setmediaBlobUrl}
handleupload={onFileUpload}
flieup={setFileuplod}
up={converttofile}
setcomment = {setcomment}
comment = {comment}
setRecfile={setRecfile}
setCommentNomagazine={setCommentNomagazine}
></RecordView>
<Formuser 
id="formOp"
OrderItemLst = {orderlist}
flieup = {fileuplod}
comment = {comment}
headerObj={header}
merchantMagazineId={merchantMagazineId}
handleprint={setelementprint}
saleIdVP={saleId}
saleIdmanVP={saleIdman}
setregionValefun={setregionValefun}
regionValefun={regionValefun}
setdelvVal={setdelvVal}
delvVal={delvVal}
></Formuser>
<div id="footer">
<Footer footer={header.footer}/>
</div>

      </>}

 
<FooterNav
 OrderItemLst = {orderlist}
 total= {total}
 filterpopup={filterpopup}
 closefilterpoup={closefilterpoup} 
 showfilterpopup={showfilterpopup}
 showpopuporder = {showpopuporder}
/>
    
    
    </div>}
   


  </div> :   <Nomagazine 
  flieup = {fileuplod}
  comment = {comment}
  merchantMagazineId={merchantMagazineId}
  Recfile={Recfile}
  setRecfile={setRecfile}
  setCommentNomagazine={setCommentNomagazine}
  CommentNomagazine={CommentNomagazine}
  
  ></Nomagazine>}
    </div>
    
  );
}


export default App;
