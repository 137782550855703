import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import '../container/container.css';
import { BsCart2 } from "react-icons/bs";
import $ from 'jquery';
import 'moment/locale/ar';
import UpArrow from '../assets/up-arrow-(1).png'
import Filter from '../assets/interface.png'


function FooterNav(props) {
    function uptohome(){
        $('html, body').animate({ scrollTop: 0 }, 50);
        return false;
      }

  return (
<div className="bottom-navigation" id='bottomnavigation'>
      <button className="nav-button"  onClick={props.showfilterpopup}> 
      {/* <CiFilter id='popupfiltericon' className='iconfiltermobile fontmaaray drop-shadow-lg'/> */}
       <img src={Filter} className='iconfiltermobile ' style={{width:'20px'}} ></img>  
      </button>
     
   
   <div className='carposation' onClick={props.showpopuporder} >
{Number(props.OrderItemLst.length).toFixed(2)==null || Number(props.OrderItemLst.length).toFixed(2)==undefined
||Number(props.OrderItemLst.length).toFixed(2)==0?
<p className='fs-6 text-white margintextofbasket' >0</p>

:<p className='fs-6 text-white margintextofbasket'  >
  {props.total.totalqut}
</p>}
<button className='btn buttonmarg'  style={{border:"none"}} onClick={props.showpopuporder}> 
<BsCart2  className='fs-4 text-white carindesktop' />
</button>

</div>
  
     
      <button id='buttonupto' className="nav-button" onClick={uptohome}>
      {/* <HiArrowSmallUp  className='iconfiltermobile  drop-shadow-lg'/> */}
      <img src={UpArrow} className='iconfiltermobile ' style={{width:'20px'}}  ></img>  
      </button>
    </div>
  );

};

export default FooterNav;